import './index.css';

import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Footer = () => {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.from('.footer-text-box h3', {
            scrollTrigger: {
                trigger: '.footer-wrapper',
                start: 'top bottom',
                toggleActions: 'play none none none'
            },
            y: 100,
            opacity: 0,
            duration: 1,
            delay: 0.3
        });

        gsap.from('.footer-link-box a', {
            scrollTrigger: {
                trigger: '.footer-wrapper',
                start: 'top bottom',
                toggleActions: 'play none none none'
            },
            y: 100,
            opacity: 0,
            duration: 1,
            stagger: 0.2,
            delay: 0.5
        });

        gsap.from('.footer-info-box', {
            scrollTrigger: {
                trigger: '.footer-wrapper',
                start: 'top bottom',
                toggleActions: 'play none none none'
            },
            y: 20,
            opacity: 0,
            duration: 1,
            delay: 0.7
        });

        gsap.from('.footer-content', {
            scrollTrigger: {
                trigger: '.footer-wrapper',
                start: 'top bottom',
                toggleActions: 'play none none none'
            },
            y: 20,
            opacity: 0,
            duration: 1,
            delay: 0.9
        });

        return () => {
            gsap.registerPlugin(ScrollTrigger);
        };
    }, []);

    return (
        <footer className="footer-wrapper">
            <div className="footer-sub-wrapper">
                <div className="footer-text-box">
                    <h3>be bold</h3>
                    <h3>be one</h3>
                    <h3>UNUS</h3>
                </div>
                <div className="footer-link-box">
                    <Link to="/about">About</Link>
                    <Link to="/portfolio">Portfolio</Link>
                    <Link to="/career">Career</Link>
                    <Link to="/contact">Contact</Link>
                </div>
                <div className="footer-info-box">
                    <div className="footer-info">
                        <p>
                            <span>Tel</span> 02-6925-5578
                        </p>
                        <p>
                            <span>Fax</span> 02-6925-5579
                        </p>
                        <p>
                            <span>Email</span> info@unuskorea.com
                        </p>
                        <p>
                            <span>Adresss</span> 서울 마포구 양화로 12길 24, 3층
                        </p>
                    </div>
                    <div className="footer-content">
                        All Contents of this proposal are the exclusive property
                        of UNUS KOREA.Inc
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
