import PT_01_01 from '@/assets/image/portfolio/pt01_01.png';
import PT_01_02 from '@/assets/image/portfolio/pt01_02.png';
import PT_01_03 from '@/assets/image/portfolio/pt01_03.png';
import PT_01_04 from '@/assets/image/portfolio/pt01_04.png';
import PT_01_05 from '@/assets/image/portfolio/pt01_05.png';
import PT_01_06 from '@/assets/image/portfolio/pt01_06.png';

import PT_02_01 from '@/assets/image/portfolio/pt02_01.png';
import PT_02_02 from '@/assets/image/portfolio/pt02_02.png';
import PT_02_03 from '@/assets/image/portfolio/pt02_03.png';
import PT_02_04 from '@/assets/image/portfolio/pt02_04.png';
import PT_02_05 from '@/assets/image/portfolio/pt02_05.png';
import PT_02_06 from '@/assets/image/portfolio/pt02_06.png';

import PT_03_01 from '@/assets/image/portfolio/pt03_01.png';
import PT_03_02 from '@/assets/image/portfolio/pt03_02.png';
import PT_03_03 from '@/assets/image/portfolio/pt03_03.png';
import PT_03_04 from '@/assets/image/portfolio/pt03_04.png';

import PT_04_01 from '@/assets/image/portfolio/pt04_01.png';
import PT_04_02 from '@/assets/image/portfolio/pt04_02.png';
import PT_04_03 from '@/assets/image/portfolio/pt04_03.png';
import PT_04_04 from '@/assets/image/portfolio/pt04_04.png';
import PT_04_05 from '@/assets/image/portfolio/pt04_05.png';
import PT_04_06 from '@/assets/image/portfolio/pt04_06.png';

import PT_05_01 from '@/assets/image/portfolio/pt05_01.png';
import PT_05_02 from '@/assets/image/portfolio/pt05_02.png';
import PT_05_03 from '@/assets/image/portfolio/pt05_03.png';
import PT_05_04 from '@/assets/image/portfolio/pt05_04.png';
import PT_05_05 from '@/assets/image/portfolio/pt05_05.png';

import PT_06_01 from '@/assets/image/portfolio/pt06_01.png';
import PT_06_02 from '@/assets/image/portfolio/pt06_02.png';
import PT_06_03 from '@/assets/image/portfolio/pt06_03.png';
import PT_06_04 from '@/assets/image/portfolio/pt06_04.png';
import PT_06_05 from '@/assets/image/portfolio/pt06_05.png';
import PT_06_06 from '@/assets/image/portfolio/pt06_06.png';

import PT_07_01 from '@/assets/image/portfolio/pt07_01.png';
import PT_07_02 from '@/assets/image/portfolio/pt07_02.png';
import PT_07_03 from '@/assets/image/portfolio/pt07_03.png';
import PT_07_04 from '@/assets/image/portfolio/pt07_04.png';
import PT_07_05 from '@/assets/image/portfolio/pt07_05.png';
import PT_07_06 from '@/assets/image/portfolio/pt07_06.png';

import PT_08_01 from '@/assets/image/portfolio/pt08_01.png';
import PT_08_02 from '@/assets/image/portfolio/pt08_02.png';
import PT_08_03 from '@/assets/image/portfolio/pt08_03.png';
import PT_08_04 from '@/assets/image/portfolio/pt08_04.png';
import PT_08_05 from '@/assets/image/portfolio/pt08_05.png';
import PT_08_06 from '@/assets/image/portfolio/pt08_06.png';

import PT_09_01 from '@/assets/image/portfolio/pt09_01.png';
import PT_09_02 from '@/assets/image/portfolio/pt09_02.png';
import PT_09_03 from '@/assets/image/portfolio/pt09_03.png';
import PT_09_04 from '@/assets/image/portfolio/pt09_04.png';
import PT_09_05 from '@/assets/image/portfolio/pt09_05.png';
import PT_09_06 from '@/assets/image/portfolio/pt09_06.png';

import PT_10_01 from '@/assets/image/portfolio/pt10_01.png';
import PT_10_02 from '@/assets/image/portfolio/pt10_02.png';
import PT_10_03 from '@/assets/image/portfolio/pt10_03.png';
import PT_10_04 from '@/assets/image/portfolio/pt10_04.png';
import PT_10_05 from '@/assets/image/portfolio/pt10_05.png';

import PT_11_01 from '@/assets/image/portfolio/pt11_01.png';
import PT_11_02 from '@/assets/image/portfolio/pt11_02.png';
import PT_11_03 from '@/assets/image/portfolio/pt11_03.png';
import PT_11_04 from '@/assets/image/portfolio/pt11_04.png';
import PT_11_05 from '@/assets/image/portfolio/pt11_05.png';
import PT_11_06 from '@/assets/image/portfolio/pt11_06.png';

import PT_12_01 from '@/assets/image/portfolio/pt12_01.png';
import PT_12_02 from '@/assets/image/portfolio/pt12_02.png';
import PT_12_03 from '@/assets/image/portfolio/pt12_03.png';
import PT_12_04 from '@/assets/image/portfolio/pt12_04.png';
import PT_12_05 from '@/assets/image/portfolio/pt12_05.png';

import PT_13_01 from '@/assets/image/portfolio/pt13_01.png';
import PT_13_02 from '@/assets/image/portfolio/pt13_02.png';
import PT_13_03 from '@/assets/image/portfolio/pt13_03.png';
import PT_13_04 from '@/assets/image/portfolio/pt13_04.png';
import PT_13_05 from '@/assets/image/portfolio/pt13_05.png';
import PT_13_06 from '@/assets/image/portfolio/pt13_06.png';

import PT_14_01 from '@/assets/image/portfolio/pt14_01.png';
import PT_14_02 from '@/assets/image/portfolio/pt14_02.png';
import PT_14_03 from '@/assets/image/portfolio/pt14_03.png';
import PT_14_04 from '@/assets/image/portfolio/pt14_04.png';

import PT_15_01 from '@/assets/image/portfolio/pt15_01.png';
import PT_15_02 from '@/assets/image/portfolio/pt15_02.png';
import PT_15_03 from '@/assets/image/portfolio/pt15_03.png';
import PT_15_04 from '@/assets/image/portfolio/pt15_04.png';
import PT_15_05 from '@/assets/image/portfolio/pt15_05.png';
import PT_15_06 from '@/assets/image/portfolio/pt15_06.png';

import PT_16_01 from '@/assets/image/portfolio/pt16_01.png';
import PT_16_02 from '@/assets/image/portfolio/pt16_02.png';
import PT_16_03 from '@/assets/image/portfolio/pt16_03.png';
import PT_16_04 from '@/assets/image/portfolio/pt16_04.png';
import PT_16_05 from '@/assets/image/portfolio/pt16_05.png';
import PT_16_06 from '@/assets/image/portfolio/pt16_06.png';

import PT_17_01 from '@/assets/image/portfolio/pt17_01.png';
import PT_17_02 from '@/assets/image/portfolio/pt17_02.png';
import PT_17_03 from '@/assets/image/portfolio/pt17_03.png';
import PT_17_04 from '@/assets/image/portfolio/pt17_04.png';
import PT_17_05 from '@/assets/image/portfolio/pt17_05.png';
import PT_17_06 from '@/assets/image/portfolio/pt17_06.png';

import PT_18_01 from '@/assets/image/portfolio/pt18_01.png';
import PT_18_02 from '@/assets/image/portfolio/pt18_02.png';
import PT_18_03 from '@/assets/image/portfolio/pt18_03.png';

import PT_19_01 from '@/assets/image/portfolio/pt19_01.png';
import PT_19_02 from '@/assets/image/portfolio/pt19_02.png';
import PT_19_03 from '@/assets/image/portfolio/pt19_03.png';
import PT_19_04 from '@/assets/image/portfolio/pt19_04.png';

import PT_20_01 from '@/assets/image/portfolio/pt20_01.png';
import PT_20_02 from '@/assets/image/portfolio/pt20_02.png';
import PT_20_03 from '@/assets/image/portfolio/pt20_03.png';
import PT_20_04 from '@/assets/image/portfolio/pt20_04.png';
import PT_20_05 from '@/assets/image/portfolio/pt20_05.png';
import PT_20_06 from '@/assets/image/portfolio/pt20_06.png';

import PT_21_01 from '@/assets/image/portfolio/pt21_01.png';
import PT_21_02 from '@/assets/image/portfolio/pt21_02.png';
import PT_21_03 from '@/assets/image/portfolio/pt21_03.png';
import PT_21_04 from '@/assets/image/portfolio/pt21_04.png';
import PT_21_05 from '@/assets/image/portfolio/pt21_05.png';

import PT_22_01 from '@/assets/image/portfolio/pt22_01.png';
import PT_22_02 from '@/assets/image/portfolio/pt22_02.png';
import PT_22_03 from '@/assets/image/portfolio/pt22_03.png';
import PT_22_04 from '@/assets/image/portfolio/pt22_04.png';
import PT_22_05 from '@/assets/image/portfolio/pt22_05.png';

import PT_23_01 from '@/assets/image/portfolio/pt23_01.png';
import PT_23_02 from '@/assets/image/portfolio/pt23_02.png';
import PT_23_03 from '@/assets/image/portfolio/pt23_03.png';
import PT_23_04 from '@/assets/image/portfolio/pt23_04.png';
import PT_23_05 from '@/assets/image/portfolio/pt23_05.png';

import PT_24_01 from '@/assets/image/portfolio/pt24_01.png';
import PT_24_02 from '@/assets/image/portfolio/pt24_02.png';
import PT_24_03 from '@/assets/image/portfolio/pt24_03.png';
import PT_24_04 from '@/assets/image/portfolio/pt24_04.png';
import PT_24_05 from '@/assets/image/portfolio/pt24_05.png';

import PT_25_01 from '@/assets/image/portfolio/pt25_01.png';
import PT_25_02 from '@/assets/image/portfolio/pt25_02.png';
import PT_25_03 from '@/assets/image/portfolio/pt25_03.png';
import PT_25_04 from '@/assets/image/portfolio/pt25_04.png';
import PT_25_05 from '@/assets/image/portfolio/pt25_05.png';

import PT_26_01 from '@/assets/image/portfolio/pt26_01.png';
import PT_26_02 from '@/assets/image/portfolio/pt26_02.png';
import PT_26_03 from '@/assets/image/portfolio/pt26_03.png';
import PT_26_04 from '@/assets/image/portfolio/pt26_04.png';
import PT_26_05 from '@/assets/image/portfolio/pt26_05.png';

import PT_27_01 from '@/assets/image/portfolio/pt27_01.png';
import PT_27_02 from '@/assets/image/portfolio/pt27_02.png';
import PT_27_03 from '@/assets/image/portfolio/pt27_03.png';
import PT_27_04 from '@/assets/image/portfolio/pt27_04.png';
import PT_27_05 from '@/assets/image/portfolio/pt27_05.png';
import PT_27_06 from '@/assets/image/portfolio/pt27_06.png';

import PT_28_01 from '@/assets/image/portfolio/pt28_01.png';
import PT_28_02 from '@/assets/image/portfolio/pt28_02.png';
import PT_28_03 from '@/assets/image/portfolio/pt28_03.png';
import PT_28_04 from '@/assets/image/portfolio/pt28_04.png';
import PT_28_05 from '@/assets/image/portfolio/pt28_05.png';
import PT_28_06 from '@/assets/image/portfolio/pt28_06.png';

import PT_29_01 from '@/assets/image/portfolio/pt29_01.png';
import PT_29_02 from '@/assets/image/portfolio/pt29_02.png';
import PT_29_03 from '@/assets/image/portfolio/pt29_03.png';
import PT_29_04 from '@/assets/image/portfolio/pt29_04.png';
import PT_29_05 from '@/assets/image/portfolio/pt29_05.png';
import PT_29_06 from '@/assets/image/portfolio/pt29_06.png';

import PT_30_01 from '@/assets/image/portfolio/pt30_01.png';
import PT_30_02 from '@/assets/image/portfolio/pt30_02.png';
import PT_30_03 from '@/assets/image/portfolio/pt30_03.png';
import PT_30_04 from '@/assets/image/portfolio/pt30_04.png';
import PT_30_05 from '@/assets/image/portfolio/pt30_05.png';
import PT_30_06 from '@/assets/image/portfolio/pt30_06.png';

import PT_31_01 from '@/assets/image/portfolio/pt31_01.png';
import PT_31_02 from '@/assets/image/portfolio/pt31_02.png';
import PT_31_03 from '@/assets/image/portfolio/pt31_03.png';
import PT_31_04 from '@/assets/image/portfolio/pt31_04.png';
import PT_31_05 from '@/assets/image/portfolio/pt31_05.png';
import PT_31_06 from '@/assets/image/portfolio/pt31_06.png';

import PT_32_01 from '@/assets/image/portfolio/pt32_01.png';
import PT_32_02 from '@/assets/image/portfolio/pt32_02.png';
import PT_32_03 from '@/assets/image/portfolio/pt32_03.png';
import PT_32_04 from '@/assets/image/portfolio/pt32_04.png';
import PT_32_05 from '@/assets/image/portfolio/pt32_05.png';
import PT_32_06 from '@/assets/image/portfolio/pt32_06.png';

import PT_33_01 from '@/assets/image/portfolio/pt33_01.png';
import PT_33_02 from '@/assets/image/portfolio/pt33_02.png';
import PT_33_03 from '@/assets/image/portfolio/pt33_03.png';
import PT_33_04 from '@/assets/image/portfolio/pt33_04.png';
import PT_33_05 from '@/assets/image/portfolio/pt33_05.png';
import PT_33_06 from '@/assets/image/portfolio/pt33_06.png';

import PT_34_01 from '@/assets/image/portfolio/pt34_01.png';
import PT_34_02 from '@/assets/image/portfolio/pt34_02.png';
import PT_34_03 from '@/assets/image/portfolio/pt34_03.png';
import PT_34_04 from '@/assets/image/portfolio/pt34_04.png';
import PT_34_05 from '@/assets/image/portfolio/pt34_05.png';
import PT_34_06 from '@/assets/image/portfolio/pt34_06.png';

import PT_35_01 from '@/assets/image/portfolio/pt35_01.png';
import PT_35_02 from '@/assets/image/portfolio/pt35_02.png';
import PT_35_03 from '@/assets/image/portfolio/pt35_03.png';
import PT_35_04 from '@/assets/image/portfolio/pt35_04.png';
import PT_35_05 from '@/assets/image/portfolio/pt35_05.png';
import PT_35_06 from '@/assets/image/portfolio/pt35_06.png';

import PT_36_01 from '@/assets/image/portfolio/pt36_01.png';
import PT_36_02 from '@/assets/image/portfolio/pt36_02.png';
import PT_36_03 from '@/assets/image/portfolio/pt36_03.png';
import PT_36_04 from '@/assets/image/portfolio/pt36_04.png';
import PT_36_05 from '@/assets/image/portfolio/pt36_05.png';
import PT_36_06 from '@/assets/image/portfolio/pt36_06.png';

import PT_37_01 from '@/assets/image/portfolio/pt37_01.png';
import PT_37_02 from '@/assets/image/portfolio/pt37_02.png';
import PT_37_03 from '@/assets/image/portfolio/pt37_03.png';
import PT_37_04 from '@/assets/image/portfolio/pt37_04.png';

import PT_38_01 from '@/assets/image/portfolio/pt38_01.png';
import PT_38_02 from '@/assets/image/portfolio/pt38_02.png';
import PT_38_03 from '@/assets/image/portfolio/pt38_03.png';
import PT_38_04 from '@/assets/image/portfolio/pt38_04.png';
import PT_38_05 from '@/assets/image/portfolio/pt38_05.png';

import PT_39_01 from '@/assets/image/portfolio/pt39_01.png';
import PT_39_02 from '@/assets/image/portfolio/pt39_02.png';
import PT_39_03 from '@/assets/image/portfolio/pt39_03.png';
import PT_39_04 from '@/assets/image/portfolio/pt39_04.png';
import PT_39_05 from '@/assets/image/portfolio/pt39_05.png';
import PT_39_06 from '@/assets/image/portfolio/pt39_06.png';

import PT_40_01 from '@/assets/image/portfolio/pt40_01.png';
import PT_40_02 from '@/assets/image/portfolio/pt40_02.png';
import PT_40_03 from '@/assets/image/portfolio/pt40_03.png';
import PT_40_04 from '@/assets/image/portfolio/pt40_04.png';
import PT_40_05 from '@/assets/image/portfolio/pt40_05.png';
import PT_40_06 from '@/assets/image/portfolio/pt40_06.png';

import PT_41_01 from '@/assets/image/portfolio/pt41_01.png';
import PT_41_02 from '@/assets/image/portfolio/pt41_02.png';
import PT_41_03 from '@/assets/image/portfolio/pt41_03.png';
import PT_41_04 from '@/assets/image/portfolio/pt41_04.png';
import PT_41_05 from '@/assets/image/portfolio/pt41_05.png';
import PT_41_06 from '@/assets/image/portfolio/pt41_06.png';

import PT_42_01 from '@/assets/image/portfolio/pt42_01.png';
import PT_42_02 from '@/assets/image/portfolio/pt42_02.png';
import PT_42_03 from '@/assets/image/portfolio/pt42_03.png';
import PT_42_04 from '@/assets/image/portfolio/pt42_04.png';
import PT_42_05 from '@/assets/image/portfolio/pt42_05.png';

import PT_43_01 from '@/assets/image/portfolio/pt43_01.png';
import PT_43_02 from '@/assets/image/portfolio/pt43_02.png';
import PT_43_03 from '@/assets/image/portfolio/pt43_03.png';
import PT_43_04 from '@/assets/image/portfolio/pt43_04.png';
import PT_43_05 from '@/assets/image/portfolio/pt43_05.png';
import PT_43_06 from '@/assets/image/portfolio/pt43_06.png';

import PT_44_01 from '@/assets/image/portfolio/pt44_01.png';
import PT_44_02 from '@/assets/image/portfolio/pt44_02.png';
import PT_44_03 from '@/assets/image/portfolio/pt44_03.png';
import PT_44_04 from '@/assets/image/portfolio/pt44_04.png';
import PT_44_05 from '@/assets/image/portfolio/pt44_05.png';
import PT_44_06 from '@/assets/image/portfolio/pt44_06.png';

import PT_45_01 from '@/assets/image/portfolio/pt45_01.png';
import PT_45_02 from '@/assets/image/portfolio/pt45_02.png';
import PT_45_03 from '@/assets/image/portfolio/pt45_03.png';
import PT_45_04 from '@/assets/image/portfolio/pt45_04.png';
import PT_45_05 from '@/assets/image/portfolio/pt45_05.png';

import PT_46_01 from '@/assets/image/portfolio/pt46_01.png';
import PT_46_02 from '@/assets/image/portfolio/pt46_02.png';
import PT_46_03 from '@/assets/image/portfolio/pt46_03.png';
import PT_46_04 from '@/assets/image/portfolio/pt46_04.png';
import PT_46_05 from '@/assets/image/portfolio/pt46_05.png';

import PT_47_01 from '@/assets/image/portfolio/pt47_01.png';
import PT_47_02 from '@/assets/image/portfolio/pt47_02.png';
import PT_47_03 from '@/assets/image/portfolio/pt47_03.png';
import PT_47_04 from '@/assets/image/portfolio/pt47_04.png';
import PT_47_05 from '@/assets/image/portfolio/pt47_05.png';
import PT_47_06 from '@/assets/image/portfolio/pt47_06.png';

import PT_48_01 from '@/assets/image/portfolio/pt48_01.png';

import PT_49_01 from '@/assets/image/portfolio/pt49_01.png';
import PT_49_02 from '@/assets/image/portfolio/pt49_02.png';
import PT_49_03 from '@/assets/image/portfolio/pt49_03.png';
import PT_49_04 from '@/assets/image/portfolio/pt49_04.png';
import PT_49_05 from '@/assets/image/portfolio/pt49_05.png';

import PT_50_01 from '@/assets/image/portfolio/pt50_01.png';
import PT_50_02 from '@/assets/image/portfolio/pt50_02.png';
import PT_50_03 from '@/assets/image/portfolio/pt50_03.png';
import PT_50_04 from '@/assets/image/portfolio/pt50_04.png';
import PT_50_05 from '@/assets/image/portfolio/pt50_05.png';

import PT_51_01 from '@/assets/image/portfolio/pt51_01.png';
import PT_51_02 from '@/assets/image/portfolio/pt51_02.png';
import PT_51_03 from '@/assets/image/portfolio/pt51_03.png';
import PT_51_04 from '@/assets/image/portfolio/pt51_04.png';
import PT_51_05 from '@/assets/image/portfolio/pt51_05.png';

import PT_52_01 from '@/assets/image/portfolio/pt52_01.png';
import PT_52_02 from '@/assets/image/portfolio/pt52_02.png';
import PT_52_03 from '@/assets/image/portfolio/pt52_03.png';
import PT_52_04 from '@/assets/image/portfolio/pt52_04.png';
import PT_52_05 from '@/assets/image/portfolio/pt52_05.png';
import PT_52_06 from '@/assets/image/portfolio/pt52_06.png';

import PT_53_01 from '@/assets/image/portfolio/pt53_01.png';
import PT_53_02 from '@/assets/image/portfolio/pt53_02.png';
import PT_53_03 from '@/assets/image/portfolio/pt53_03.png';
import PT_53_04 from '@/assets/image/portfolio/pt53_04.png';
import PT_53_05 from '@/assets/image/portfolio/pt53_05.png';
import PT_53_06 from '@/assets/image/portfolio/pt53_06.png';

import PT_54_01 from '@/assets/image/portfolio/pt54_01.png';
import PT_54_02 from '@/assets/image/portfolio/pt54_02.png';
import PT_54_03 from '@/assets/image/portfolio/pt54_03.png';
import PT_54_04 from '@/assets/image/portfolio/pt54_04.png';
import PT_54_05 from '@/assets/image/portfolio/pt54_05.png';
import PT_54_06 from '@/assets/image/portfolio/pt54_06.png';

import PT_55_01 from '@/assets/image/portfolio/pt55_01.png';
import PT_55_02 from '@/assets/image/portfolio/pt55_02.png';
import PT_55_03 from '@/assets/image/portfolio/pt55_03.png';
import PT_55_04 from '@/assets/image/portfolio/pt55_04.png';
import PT_55_05 from '@/assets/image/portfolio/pt55_05.png';

import PT_56_01 from '@/assets/image/portfolio/pt56_01.png';
import PT_56_02 from '@/assets/image/portfolio/pt56_02.png';
import PT_56_03 from '@/assets/image/portfolio/pt56_03.png';
import PT_56_04 from '@/assets/image/portfolio/pt56_04.png';
import PT_56_05 from '@/assets/image/portfolio/pt56_05.png';
import PT_56_06 from '@/assets/image/portfolio/pt56_06.png';

import PT_57_01 from '@/assets/image/portfolio/pt57_01.png';
import PT_57_02 from '@/assets/image/portfolio/pt57_02.png';
import PT_57_03 from '@/assets/image/portfolio/pt57_03.png';
import PT_57_04 from '@/assets/image/portfolio/pt57_04.png';
import PT_57_05 from '@/assets/image/portfolio/pt57_05.png';
import PT_57_06 from '@/assets/image/portfolio/pt57_06.png';

import PT_58_01 from '@/assets/image/portfolio/pt58_01.png';
import PT_58_02 from '@/assets/image/portfolio/pt58_02.png';
import PT_58_03 from '@/assets/image/portfolio/pt58_03.png';
import PT_58_04 from '@/assets/image/portfolio/pt58_04.png';
import PT_58_05 from '@/assets/image/portfolio/pt58_05.png';
import PT_58_06 from '@/assets/image/portfolio/pt58_06.png';

import PT_59_01 from '@/assets/image/portfolio/pt59_01.png';
import PT_59_02 from '@/assets/image/portfolio/pt59_02.png';
import PT_59_03 from '@/assets/image/portfolio/pt59_03.png';
import PT_59_04 from '@/assets/image/portfolio/pt59_04.png';
import PT_59_05 from '@/assets/image/portfolio/pt59_05.png';

import PT_60_01 from '@/assets/image/portfolio/pt60_01.png';
import PT_60_02 from '@/assets/image/portfolio/pt60_02.png';
import PT_60_03 from '@/assets/image/portfolio/pt60_03.png';
import PT_60_04 from '@/assets/image/portfolio/pt60_04.png';
import PT_60_05 from '@/assets/image/portfolio/pt60_05.png';
import PT_60_06 from '@/assets/image/portfolio/pt60_06.png';

import PT_61_01 from '@/assets/image/portfolio/pt61_01.png';
import PT_61_02 from '@/assets/image/portfolio/pt61_02.png';
import PT_61_03 from '@/assets/image/portfolio/pt61_03.png';
import PT_61_04 from '@/assets/image/portfolio/pt61_04.png';
import PT_61_05 from '@/assets/image/portfolio/pt61_05.png';
import PT_61_06 from '@/assets/image/portfolio/pt61_06.png';

import PT_62_01 from '@/assets/image/portfolio/pt62_01.png';
import PT_62_02 from '@/assets/image/portfolio/pt62_02.png';
import PT_62_03 from '@/assets/image/portfolio/pt62_03.png';

import PT_63_01 from '@/assets/image/portfolio/pt63_01.jpg';
import PT_63_02 from '@/assets/image/portfolio/pt63_02.jpeg';
import PT_63_03 from '@/assets/image/portfolio/pt63_03.jpg';
import PT_63_04 from '@/assets/image/portfolio/pt63_04.jpg';
import PT_63_05 from '@/assets/image/portfolio/pt63_05.jpg';

import PT_64_01 from '@/assets/image/portfolio/pt64_01.jpg';
import PT_64_02 from '@/assets/image/portfolio/pt64_02.jpg';
import PT_64_03 from '@/assets/image/portfolio/pt64_03.jpg';
import PT_64_04 from '@/assets/image/portfolio/pt64_04.jpg';
import PT_64_05 from '@/assets/image/portfolio/pt64_05.jpg';

import PT_65_01 from '@/assets/image/portfolio/pt65_01.png';
import PT_65_02 from '@/assets/image/portfolio/pt65_02.png';
import PT_65_03 from '@/assets/image/portfolio/pt65_03.png';
import PT_65_04 from '@/assets/image/portfolio/pt65_04.jpg';
import PT_65_05 from '@/assets/image/portfolio/pt65_05.jpg';

import PT_66_01 from '@/assets/image/portfolio/pt66_01.jpg';
import PT_66_02 from '@/assets/image/portfolio/pt66_02.jpg';
import PT_66_03 from '@/assets/image/portfolio/pt66_03.jpg';
import PT_66_04 from '@/assets/image/portfolio/pt66_04.jpg';
import PT_66_05 from '@/assets/image/portfolio/pt66_05.jpg';

// 1번 포트폴리오 이미지
export const portfolio1 = [
    [PT_01_01],
    [PT_01_02, PT_01_03],
    [PT_01_04],
    [PT_01_05],
    [PT_01_06]
];

// 2번 포트폴리오 이미지
export const portfolio2 = [
    [PT_02_01],
    [PT_02_02, PT_02_03],
    [PT_02_04],
    [PT_02_05],
    [PT_02_06]
];

// 3번 포트폴리오 이미지
export const portfolio3 = [[PT_03_01], [PT_03_02], [PT_03_03], [PT_03_04]];

// 4번 포트폴리오 이미지
export const portfolio4 = [
    [PT_04_01],
    [PT_04_02, PT_04_03],
    [PT_04_04],
    [PT_04_05],
    [PT_04_06]
];

// 5번 포트폴리오 이미지
export const portfolio5 = [
    [PT_05_01],
    [PT_05_02, PT_05_03],
    [PT_05_04],
    [PT_05_05]
];

// 6번 포트폴리오 이미지
export const portfolio6 = [
    [PT_06_01],
    [PT_06_02, PT_06_03],
    [PT_06_04],
    [PT_06_05],
    [PT_06_06]
];

// 7번 포트폴리오 이미지
export const portfolio7 = [
    [PT_07_01],
    [PT_07_02, PT_07_03],
    [PT_07_04],
    [PT_07_05],
    [PT_07_06]
];

// 8번 포트폴리오 이미지
export const portfolio8 = [
    [PT_08_01],
    [PT_08_02, PT_08_03],
    [PT_08_04],
    [PT_08_05],
    [PT_08_06]
];

// 9번 포트폴리오 이미지
export const portfolio9 = [
    [PT_09_01],
    [PT_09_02, PT_09_03],
    [PT_09_04],
    [PT_09_05],
    [PT_09_06]
];

// 10번 포트폴리오 이미지
export const portfolio10 = [
    [PT_10_01],
    [PT_10_02, PT_10_03],
    [PT_10_04],
    [PT_10_05]
];

// 11번 포트폴리오 이미지
export const portfolio11 = [
    [PT_11_01],
    [PT_11_02, PT_11_03],
    [PT_11_04],
    [PT_11_05],
    [PT_11_06]
];

// 12번 포트폴리오 이미지
export const portfolio12 = [
    [PT_12_01],
    [PT_12_02, PT_12_03],
    [PT_12_04],
    [PT_12_05]
];

// 13번 포트폴리오 이미지
export const portfolio13 = [
    [PT_13_01],
    [PT_13_02, PT_13_03],
    [PT_13_04],
    [PT_13_05],
    [PT_13_06]
];

// 14번 포트폴리오 이미지
export const portfolio14 = [[PT_14_01], [PT_14_02], [PT_14_03], [PT_14_04]];

// 15번 포트폴리오 이미지
export const portfolio15 = [
    [PT_15_01],
    [PT_15_02, PT_15_03],
    [PT_15_04],
    [PT_15_05],
    [PT_15_06]
];

// 16번 포트폴리오 이미지
export const portfolio16 = [
    [PT_16_01],
    [PT_16_02, PT_16_03],
    [PT_16_04],
    [PT_16_05],
    [PT_16_06]
];

// 17번 포트폴리오 이미지
export const portfolio17 = [
    [PT_17_01],
    [PT_17_02, PT_17_03],
    [PT_17_04],
    [PT_17_05],
    [PT_17_06]
];

// 18번 포트폴리오 이미지
export const portfolio18 = [[PT_18_01], [PT_18_02], [PT_18_03]];

// 19번 포트폴리오 이미지
export const portfolio19 = [[PT_19_01], [PT_19_02], [PT_19_03], [PT_19_04]];

// 20번 포트폴리오 이미지
export const portfolio20 = [
    [PT_20_01],
    [PT_20_02, PT_20_03],
    [PT_20_04],
    [PT_20_05],
    [PT_20_06]
];

// 21번 포트폴리오 이미지
export const portfolio21 = [
    [PT_21_01],
    [PT_21_02, PT_21_03],
    [PT_21_04],
    [PT_21_05]
];

// 22번 포트폴리오 이미지
export const portfolio22 = [
    [PT_22_01],
    [PT_22_02, PT_22_03],
    [PT_22_04],
    [PT_22_05]
];

// 23번 포트폴리오 이미지
export const portfolio23 = [
    [PT_23_01],
    [PT_23_02, PT_23_03],
    [PT_23_04],
    [PT_23_05]
];

// 24번 포트폴리오 이미지
export const portfolio24 = [
    [PT_24_01],
    [PT_24_02, PT_24_03],
    [PT_24_04],
    [PT_24_05]
];

// 25번 포트폴리오 이미지
export const portfolio25 = [
    [PT_25_01],
    [PT_25_02, PT_25_03],
    [PT_25_04],
    [PT_25_05]
];

// 26번 포트폴리오 이미지
export const portfolio26 = [
    [PT_26_01],
    [PT_26_02, PT_26_03],
    [PT_26_04],
    [PT_26_05]
];

// 27번 포트폴리오 이미지
export const portfolio27 = [
    [PT_27_01],
    [PT_27_02, PT_27_03],
    [PT_27_04],
    [PT_27_05],
    [PT_27_06]
];

// 28번 포트폴리오 이미지
export const portfolio28 = [
    [PT_28_01],
    [PT_28_02, PT_28_03],
    [PT_28_04],
    [PT_28_05],
    [PT_28_06]
];

// 29번 포트폴리오 이미지
export const portfolio29 = [
    [PT_29_01],
    [PT_29_02, PT_29_03],
    [PT_29_04],
    [PT_29_05],
    [PT_29_06]
];

// 30번 포트폴리오 이미지
export const portfolio30 = [
    [PT_30_01],
    [PT_30_02, PT_30_03],
    [PT_30_04],
    [PT_30_05],
    [PT_30_06]
];

// 31번 포트폴리오 이미지
export const portfolio31 = [
    [PT_31_01],
    [PT_31_02, PT_31_03],
    [PT_31_04],
    [PT_31_05],
    [PT_31_06]
];

// 32번 포트폴리오 이미지
export const portfolio32 = [
    [PT_32_01],
    [PT_32_02, PT_32_03],
    [PT_32_04],
    [PT_32_05],
    [PT_32_06]
];

// 33번 포트폴리오 이미지
export const portfolio33 = [
    [PT_33_01],
    [PT_33_02, PT_33_03],
    [PT_33_04],
    [PT_33_05],
    [PT_33_06]
];

// 34번 포트폴리오 이미지
export const portfolio34 = [
    [PT_34_01],
    [PT_34_02, PT_34_03],
    [PT_34_04],
    [PT_34_05],
    [PT_34_06]
];

// 35번 포트폴리오 이미지
export const portfolio35 = [
    [PT_35_01],
    [PT_35_02, PT_35_03],
    [PT_35_04],
    [PT_35_05],
    [PT_35_06]
];

// 36번 포트폴리오 이미지
export const portfolio36 = [
    [PT_36_01],
    [PT_36_02, PT_36_03],
    [PT_36_04],
    [PT_36_05],
    [PT_36_06]
];

// 37번 포트폴리오 이미지
export const portfolio37 = [[PT_37_01], [PT_37_02], [PT_37_03], [PT_37_04]];

// 38번 포트폴리오 이미지
export const portfolio38 = [
    [PT_38_01],
    [PT_38_02, PT_38_03],
    [PT_38_04],
    [PT_38_05]
];

// 39번 포트폴리오 이미지
export const portfolio39 = [
    [PT_39_01],
    [PT_39_02, PT_39_03],
    [PT_39_04],
    [PT_39_05],
    [PT_39_06]
];

// 40번 포트폴리오 이미지
export const portfolio40 = [
    [PT_40_01],
    [PT_40_02, PT_40_03],
    [PT_40_04],
    [PT_40_05],
    [PT_40_06]
];

// 41번 포트폴리오 이미지
export const portfolio41 = [
    [PT_41_01],
    [PT_41_02, PT_41_03],
    [PT_41_04],
    [PT_41_05],
    [PT_41_06]
];

// 42번 포트폴리오 이미지
export const portfolio42 = [
    [PT_42_01],
    [PT_42_02, PT_42_03],
    [PT_42_04],
    [PT_42_05]
];

// 43번 포트폴리오 이미지
export const portfolio43 = [
    [PT_43_01],
    [PT_43_02, PT_43_03],
    [PT_43_04],
    [PT_43_05],
    [PT_43_06]
];

// 44번 포트폴리오 이미지
export const portfolio44 = [
    [PT_44_01],
    [PT_44_02, PT_44_03],
    [PT_44_04],
    [PT_44_05],
    [PT_44_06]
];

// 45번 포트폴리오 이미지
export const portfolio45 = [
    [PT_45_01],
    [PT_45_02, PT_45_03],
    [PT_45_04],
    [PT_45_05]
];

// 46번 포트폴리오 이미지
export const portfolio46 = [
    [PT_46_01],
    [PT_46_02, PT_46_03],
    [PT_46_04],
    [PT_46_05]
];

// 47번 포트폴리오 이미지
export const portfolio47 = [
    [PT_47_01],
    [PT_47_02, PT_47_03],
    [PT_47_04],
    [PT_47_05],
    [PT_47_06]
];

// 48번 포트폴리오 이미지
export const portfolio48 = [[PT_48_01]];

// 49번 포트폴리오 이미지
export const portfolio49 = [
    [PT_49_01],
    [PT_49_02, PT_49_03],
    [PT_49_04],
    [PT_49_05]
];

// 50번 포트폴리오 이미지
export const portfolio50 = [
    [PT_50_01],
    [PT_50_02, PT_50_03],
    [PT_50_04],
    [PT_50_05]
];

// 51번 포트폴리오 이미지
export const portfolio51 = [
    [PT_51_01],
    [PT_51_02, PT_51_03],
    [PT_51_04],
    [PT_51_05]
];

// 52번 포트폴리오 이미지
export const portfolio52 = [
    [PT_52_01],
    [PT_52_02, PT_52_03],
    [PT_52_04],
    [PT_52_05],
    [PT_52_06]
];

// 53번 포트폴리오 이미지
export const portfolio53 = [
    [PT_53_01],
    [PT_53_02, PT_53_03],
    [PT_53_04],
    [PT_53_05],
    [PT_53_06]
];

// 54번 포트폴리오 이미지
export const portfolio54 = [
    [PT_54_01],
    [PT_54_02, PT_54_03],
    [PT_54_04],
    [PT_54_05],
    [PT_54_06]
];

// 55번 포트폴리오 이미지
export const portfolio55 = [
    [PT_55_01],
    [PT_55_02, PT_55_03],
    [PT_55_04],
    [PT_55_05]
];

// 56번 포트폴리오 이미지
export const portfolio56 = [
    [PT_56_01],
    [PT_56_02, PT_56_03],
    [PT_56_04],
    [PT_56_05],
    [PT_56_06]
];

// 57번 포트폴리오 이미지
export const portfolio57 = [
    [PT_57_01],
    [PT_57_02, PT_57_03],
    [PT_57_04],
    [PT_57_05],
    [PT_57_06]
];

// 58번 포트폴리오 이미지
export const portfolio58 = [
    [PT_58_01],
    [PT_58_02, PT_58_03],
    [PT_58_04],
    [PT_58_05],
    [PT_58_06]
];

// 59번 포트폴리오 이미지
export const portfolio59 = [
    [PT_59_01],
    [PT_59_02, PT_59_03],
    [PT_59_04],
    [PT_59_05]
];

// 60번 포트폴리오 이미지
export const portfolio60 = [
    [PT_60_01],
    [PT_60_02, PT_60_03],
    [PT_60_04],
    [PT_60_05],
    [PT_60_06]
];

// 61번 포트폴리오 이미지
export const portfolio61 = [
    [PT_61_01],
    [PT_61_02, PT_61_03],
    [PT_61_04],
    [PT_61_05],
    [PT_61_06]
];

// 62번 포트폴리오 이미지
export const portfolio62 = [[PT_62_01], [PT_62_02], [PT_62_03]];

// 63번 포트폴리오 이미지
export const portfolio63 = [
    [PT_63_01],
    [PT_63_02, PT_63_03],
    [PT_63_04],
    [PT_63_05]
];

// 64번 포트폴리오 이미지
export const portfolio64 = [
    [PT_64_01],
    [PT_64_02, PT_64_03],
    [PT_64_04],
    [PT_64_05]
];

// 65번 포트폴리오 이미지
export const portfolio65 = [
    [PT_65_01],
    [PT_65_02, PT_65_03],
    [PT_65_04],
    [PT_65_05]
];

// 66번 포트폴리오 리스트
export const portfolio66 = [
    [PT_66_01, PT_66_02],
    [PT_66_03],
    [PT_66_04, PT_66_05]
];
