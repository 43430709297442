import './index.css';

import { useEffect } from 'react';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import WELFARE_TITLE from '@/assets/image/carrer/welfare_title.png';
import WELFARE_TITLE_SMALL from '@/assets/image/carrer/welfare_title_sm.png';

import WELFARE_ICON_01 from '@/assets/image/carrer/welfare_icon_01.png';
import WELFARE_ICON_02 from '@/assets/image/carrer/welfare_icon_02.png';
import WELFARE_ICON_03 from '@/assets/image/carrer/welfare_icon_03.png';
// import WELFARE_ICON_04 from '@/assets/image/carrer/welfare_icon_04.png';
import WELFARE_ICON_05 from '@/assets/image/carrer/welfare_icon_05.png';
import WELFARE_ICON_06 from '@/assets/image/carrer/welfare_icon_06.png';
import { useMediaQuery } from '@mui/material';

const Welfare = () => {
    const mb = useMediaQuery('(max-width: 745px)');

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.from('.welfare-box', {
            y: 50,
            opacity: 0,
            duration: 2,
            ease: 'power2.out',
            scrollTrigger: {
                trigger: '.welfare-box',
                start: 'top 90%',
                toggleActions: 'play none none none'
            }
        });

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.welfare-card:first-child',
                start: 'top 90%',
                toggleActions: 'play none none none'
            }
        });

        gsap.utils.toArray('.welfare-card').forEach((card, index) => {
            tl.fromTo(
                card as any,
                {
                    y: 50,
                    autoAlpha: 0
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 1,
                    delay: index * 0.2,
                    ease: 'power2.out'
                },
                0
            );
        });
    }, []);

    return (
        <section className="welfare-wrapper">
            <div className="welfare-box">
                <h2>
                    <img
                        src={mb ? WELFARE_TITLE_SMALL : WELFARE_TITLE}
                        alt="복지 타이틀"
                    />
                </h2>

                <div className="welfare-card-box">
                    <div className="welfare-card">
                        <div className="welfare-card-icon">
                            <img src={WELFARE_ICON_01} alt="복지 아이콘" />
                        </div>

                        <div className="welfare-card-content">
                            <p>연금•보험</p>
                            <div>
                                {[
                                    '국민연금',
                                    '고용보험',
                                    '산재보험',
                                    '건강보험',
                                    '직장인 단체보험'
                                ].map((title, index) => (
                                    <p key={index}>{title}</p>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="welfare-card">
                        <div className="welfare-card-icon">
                            <img src={WELFARE_ICON_02} alt="복지 아이콘" />
                        </div>

                        <div className="welfare-card-content">
                            <p>휴무•휴가•행사</p>
                            <div>
                                {[
                                    '연차제도',
                                    '경조휴가',
                                    '반차제도',
                                    '대체휴무',
                                    '출산휴가',
                                    '남성출산휴가',
                                    '보건휴가',
                                    '노동절 휴무'
                                ].map((title, index) => (
                                    <p key={index}>{title}</p>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="welfare-card">
                        <div className="welfare-card-icon">
                            <img src={WELFARE_ICON_03} alt="복지 아이콘" />
                        </div>

                        <div className="welfare-card-content">
                            <p>보상•수당•지원</p>
                            <div>
                                {[
                                    '퇴직금',
                                    '경조금',
                                    '인센티브',
                                    '야근식대지원',
                                    '야간 교통비 지원',
                                    '도서 구입비 지원',
                                    '휴가비',
                                    '탕비실',
                                    '간식제공'
                                ].map((title, index) => (
                                    <p key={index}>{title}</p>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* <div className="welfare-card">
                        <div className="welfare-card-icon">
                            <img src={WELFARE_ICON_04} alt="복지 아이콘" />
                        </div>

                        <div className="welfare-card-content">
                            <p>사내시설</p>
                            <div>
                                {['사내정원', '회의실', '전용사옥'].map(
                                    (title, index) => (
                                        <p key={index}>{title}</p>
                                    )
                                )}
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="welfare-card">
                        <div className="welfare-card-icon">
                            <img src={WELFARE_ICON_05} alt="복지 아이콘" />
                        </div>

                        <div className="welfare-card-content">
                            <p>편의•건강•여가</p>
                            <div>
                                {['건강검진'].map((title, index) => (
                                    <p key={index}>{title}</p>
                                ))}
                            </div>
                        </div>
                    </div> */}

                    <div className="welfare-card">
                        <div className="welfare-card-icon">
                            <img src={WELFARE_ICON_06} alt="복지 아이콘" />
                        </div>

                        <div className="welfare-card-content">
                            <p>기타</p>
                            <div>
                                {[
                                    '명절선물',
                                    '생일선물',
                                    '출산선물',
                                    '자유복장',
                                    '건강검진',
                                    '자유로운 연차 사용'
                                ].map((title, index) => (
                                    <p key={index}>{title}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Welfare;
