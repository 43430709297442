import './index.css';

import { useEffect, useState } from 'react';

import emailjs from '@emailjs/browser';

import { ReactComponent as CheckBoxActive } from '@/assets/image/contact/check_box_active.svg';
import { ReactComponent as CheckBoxDisable } from '@/assets/image/contact/check_box_disable.svg';

import { ReactComponent as SendIcon } from '@/assets/image/contact/send_icon.svg';
import { ReactComponent as SendIconDisable } from '@/assets/image/contact/send_disable_icon.svg';

const ContactForm = () => {
    const [isAgree, setIsAgree] = useState<boolean>(false);
    const [isActived, setIsActived] = useState<boolean>(false);
    const [form, setForm] = useState({
        name: '',
        company: '',
        phone: '',
        email: '',
        message: ''
    });

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isAgree) return alert('개인정보 수집 및 이용에 동의 해주세요.');
        if (!form.name) return alert('이름을 입력해 주세요.');
        if (!form.company) return alert('회사명을 입력해 주세요.');
        if (!form.phone) return alert('전화번호를 입력해 주세요.');
        if (!form.email) return alert('이메일을 입력해 주세요.');
        if (!form.message) return alert('문의 내용을 입력해 주세요.');

        // Email Js 변수
        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID as string;
        const templeteId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string;
        const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string;

        const templeteParams = {
            fromName: form.name,
            fromCompany: form.company,
            fromPhone: form.phone,
            fromEmail: form.email,
            fromMessage: form.message
        };

        const confirmed = window.confirm('문의 내용을 전송 하시겠습니까?');

        if (!confirmed) return;

        emailjs
            .send(serviceId, templeteId, templeteParams, publicKey)
            .then(res => {
                alert('문의 내용이 전송 되었습니다.');
                console.log(res);
                setForm({
                    name: '',
                    company: '',
                    phone: '',
                    email: '',
                    message: ''
                });
                setIsAgree(false);
            })
            .catch(err => {
                alert('문의 내용 전송에 실패 하였습니다.');
            });
    };

    useEffect(() => {
        if (
            form.name &&
            form.company &&
            form.phone &&
            form.email &&
            form.message &&
            isAgree
        ) {
            setIsActived(true);
        } else {
            setIsActived(false);
        }
    }, [form, isAgree]);

    return (
        <section className="contact-form-wrapper">
            <form
                className="contact-form-box"
                autoComplete="off"
                onSubmit={handleFormSubmit}
            >
                <div className="form name">
                    <p>
                        Name
                        <span />
                    </p>
                    <input
                        type="text"
                        placeholder="담당자 성함을 입력해 주세요"
                        name="name"
                        value={form.name}
                        onChange={handleFormChange}
                    />
                </div>
                <div className="form company">
                    <p>
                        Company
                        <span />
                    </p>
                    <input
                        type="text"
                        placeholder="회사명을 입력해 주세요"
                        name="company"
                        value={form.company}
                        onChange={handleFormChange}
                    />
                </div>
                <div className="form phone">
                    <p>
                        Contact number
                        <span />
                    </p>
                    <input
                        type="text"
                        placeholder="연락받으실 전화번호를 입력해 주세요"
                        name="phone"
                        value={form.phone}
                        onChange={handleFormChange}
                    />
                </div>
                <div className="form email">
                    <p>
                        Email
                        <span />
                    </p>
                    <input
                        type="text"
                        placeholder="연락받으실 이메일을 입력해 주세요"
                        name="email"
                        value={form.email}
                        onChange={handleFormChange}
                    />
                </div>
                <div className="form message">
                    <p>
                        Message
                        <span />
                    </p>
                    <textarea
                        placeholder="문의 내용을 입력해 주세요"
                        rows={5}
                        cols={50}
                        name="message"
                        value={form.message}
                        onChange={handleFormChange as any}
                    />
                </div>

                <div className="form agreement">
                    <div className="agreement-box">
                        <div className="check-box">
                            {isAgree ? (
                                <CheckBoxActive
                                    onClick={() => setIsAgree(!isAgree)}
                                />
                            ) : (
                                <CheckBoxDisable
                                    onClick={() => setIsAgree(!isAgree)}
                                />
                            )}
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => setIsAgree(!isAgree)}
                            >
                                개인정보 수집 및 이용에 동의합니다.
                            </span>
                        </div>
                        <p>
                            본 신청 페이지를 통해 (주)UNUS KOREA 신청자의
                            기업명, 성함, 직책, 이메일 , 연락처를 수집하고
                            있습니다. 수집 정보는 UNUS KOREA 문의 및 응대를 위해
                            사용하며 12개월 동안 보유 및 이용합니다.
                        </p>
                    </div>
                    <div className={`send-box ${isActived ? '' : 'disable'}`}>
                        <button type="submit">
                            Send
                            {isActived ? <SendIcon /> : <SendIconDisable />}
                        </button>
                    </div>
                </div>
            </form>
        </section>
    );
};

export default ContactForm;
