import './index.css';

import { useEffect, useRef } from 'react';
import { useMediaQuery } from '@mui/material';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import BN_TITLE_IMG from '@/assets/image/about/bn_title.png';

import { aboutBnContent } from '@/utils/contents';

const Business = () => {
    const cardBoxRef = useRef<any>(null);

    const mb = useMediaQuery('(max-width: 745px)');

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const cardBoxes = gsap.utils.toArray('.business-card');

        if (!mb) {
            cardBoxes.forEach((card: any) => {
                return gsap.to(card, {
                    x: 0,
                    scrollTrigger: {
                        trigger: card,
                        scrub: true
                    },
                    onStart: () => {
                        ScrollTrigger.refresh();
                    }
                });
            });

            const container = cardBoxRef.current;

            gsap.to(container, {
                x: () =>
                    `${-(
                        container.scrollWidth -
                        document.documentElement.clientWidth +
                        100
                    )}px`,
                ease: 'none',
                scrollTrigger: {
                    trigger: container,
                    invalidateOnRefresh: true,
                    pin: true,
                    anticipatePin: 1,
                    scrub: 1,
                    start: 'top top',
                    end: () => `+=${container.offsetWidth}`
                }
            });

            ScrollTrigger.sort();
        }

        if (mb) {
            const cardBoxes = gsap.utils.toArray('.business-card');

            gsap.to(cardBoxRef.current, {
                scrollTrigger: {
                    trigger: '.business-card-box',
                    start: 'top 80%'
                },
                onStart: () => {
                    ScrollTrigger.refresh();
                }
            });

            cardBoxes.forEach((card: any, index) => {
                gsap.fromTo(
                    card,
                    {
                        autoAlpha: 0,
                        y: 50
                    },
                    {
                        autoAlpha: 1,
                        y: 0,
                        duration: 0.5,
                        ease: 'power1.out',
                        scrollTrigger: {
                            trigger: card,
                            start: 'top 80%',
                            toggleActions: 'play none none none'
                        }
                    }
                );

                if (index % 2 === 0) {
                    gsap.fromTo(
                        card,
                        {
                            x: 100
                        },
                        {
                            x: 0,
                            duration: 0.5,
                            ease: 'power1.out',
                            scrollTrigger: {
                                trigger: card,
                                start: 'top 80%',
                                toggleActions: 'play none none none'
                            }
                        }
                    );
                } else {
                    gsap.fromTo(
                        card,
                        {
                            x: -100
                        },
                        {
                            x: 0,
                            duration: 0.5,
                            ease: 'power1.out',
                            scrollTrigger: {
                                trigger: card,
                                start: 'top 80%',
                                toggleActions: 'play none none none'
                            }
                        }
                    );
                }
            });
        }
    }, [mb]);

    return (
        <section className="business-wrapper">
            <div className="business-sub-wrapper" ref={cardBoxRef}>
                <div className="business-title-box">
                    {!mb && (
                        <img src={BN_TITLE_IMG} alt="비지니스 타이틀 이미지" />
                    )}
                    {mb && (
                        <p>
                            Our <span className="italic-text">business</span>
                        </p>
                    )}
                </div>

                {!mb && (
                    <div className="business-card-box">
                        {aboutBnContent.map((content, index) => (
                            <div
                                className="business-card"
                                key={index}
                                style={{
                                    transform:
                                        index % 2 === 0
                                            ? 'translateY(0)'
                                            : 'translateY(100px)'
                                }}
                            >
                                <div className="business-card-img">
                                    <img
                                        src={content.img}
                                        loading="lazy"
                                        alt="비지니스 카드 이미지"
                                    />
                                </div>
                                <div className="business-card-content">
                                    <h3>{content.title}</h3>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: content.desc
                                        }}
                                    ></p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {mb && (
                    <div className="business-card-box">
                        {aboutBnContent.map((content, index) => (
                            <div
                                className="business-card"
                                key={index}
                                style={{
                                    width: index % 2 === 0 ? '100%' : '0'
                                }}
                            >
                                <div className="business-card-img">
                                    <img
                                        src={content.img}
                                        loading="lazy"
                                        alt="비지니스 카드 이미지"
                                    />
                                </div>
                                <div className="business-card-content">
                                    <h3>{content.title}</h3>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: content.desc
                                        }}
                                    ></p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default Business;
