import './index.css';
import { useEffect, useRef } from 'react';
import { useMediaQuery } from '@mui/material';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import { aboutFadeContent } from '@/utils/contents';

Swiper.use([Autoplay, Pagination]);

const FadeCard = () => {
    const mb = useMediaQuery('(max-width: 745px)');
    const swiperRef = useRef<any>(null);

    // useEffect(() => {
    //     const fadeCard = document.querySelectorAll('.fade-card');
    //     const fadeCardTextBox = document.querySelector('.fade-card-text-box');

    //     gsap.fromTo(
    //         fadeCardTextBox,
    //         {
    //             y: 50,
    //             opacity: 0
    //         },
    //         {
    //             duration: 0.5,
    //             y: 0,
    //             opacity: 1,
    //             ease: 'sine.inOut'
    //         }
    //     );

    //     fadeCard.forEach((card, index) => {
    //         gsap.fromTo(
    //             card,
    //             {
    //                 y: 50,
    //                 opacity: 0
    //             },
    //             {
    //                 duration: 0.5,
    //                 y: 0,
    //                 opacity: 1,
    //                 ease: 'sine.inOut',
    //                 delay: index * 0.5
    //             }
    //         );
    //     });
    // }, []);

    useEffect(() => {
        // ScrollTrigger 플러그인을 GSAP에 등록합니다.
        gsap.registerPlugin(ScrollTrigger);

        const fadeCards = document.querySelectorAll('.fade-card');
        const fadeCardTextBox = document.querySelector('.fade-card-text-box');

        // fadeCardTextBox에 대한 애니메이션
        gsap.fromTo(
            fadeCardTextBox,
            {
                y: 50,
                opacity: 0
            },
            {
                duration: 0.5,
                y: 0,
                opacity: 1,
                ease: 'sine.inOut',
                scrollTrigger: {
                    trigger: '.fade-card-warapper',
                    start: 'top center',
                    toggleActions: 'play none none none'
                }
            }
        );

        // fadeCards에 대한 애니메이션
        fadeCards.forEach((card, index) => {
            gsap.fromTo(
                card,
                {
                    y: 50,
                    opacity: 0
                },
                {
                    duration: 0.5,
                    y: 0,
                    opacity: 1,
                    ease: 'sine.inOut',
                    delay: index * 0.3,
                    scrollTrigger: {
                        trigger: card,
                        start: 'top center+=100',
                        toggleActions: 'play none none none'
                    }
                }
            );
        });

        ScrollTrigger.refresh();
    }, []);

    useEffect(() => {
        let swiperInstance: any;

        if (mb) {
            swiperInstance = new Swiper(swiperRef.current, {
                slidesPerView: 'auto',
                autoHeight: true,
                centeredSlides: true,
                spaceBetween: 10,
                loop: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: (index, className) => {
                        const activeClass =
                            index === swiperInstance?.realIndex ? 'active' : '';
                        return `<span class="${className} ${activeClass}"></span>`;
                    }
                }
            });
        }

        return () => {
            if (swiperInstance) {
                swiperInstance.destroy();
            }
        };
    }, [mb]);

    return (
        <section className="fade-card-warapper">
            <div className="fade-card-text-box">
                <h3>
                    we provide <span>the best solutions</span>
                    <br />
                    to make the world <span>valuable</span>
                </h3>
            </div>
            <div className="fade-card-box">
                {mb && (
                    <div className="swiper-container" ref={swiperRef}>
                        <div className="swiper-wrapper">
                            {aboutFadeContent.map((content, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="swiper-slide fade-card"
                                    >
                                        <div className="fade-card-img">
                                            <img
                                                src={content.img}
                                                alt="fade-card-img"
                                            />
                                        </div>
                                        <div className="fade-card-text">
                                            <div className="fade-card-text-top">
                                                <h5>({index + 1})</h5>
                                                <h4>{content.title}</h4>
                                            </div>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: content.desc
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                )}

                {!mb && (
                    <div className="fade-card-sub-box">
                        {aboutFadeContent.map((content, index) => {
                            return (
                                <div key={index} className="fade-card">
                                    <div className="fade-card-img">
                                        <img
                                            src={content.img}
                                            alt="fade-card-img"
                                        />
                                    </div>
                                    <div className="fade-card-text">
                                        <div className="fade-card-text-top">
                                            <h5>({index + 1})</h5>
                                            <h4>{content.title}</h4>
                                        </div>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: content.desc
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </section>
    );
};

export default FadeCard;
