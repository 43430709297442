import './index.css';

interface ContentProps {
    content: any;
}

const Content = ({ content }: ContentProps) => {
    const { images } = content;

    return (
        <section
            className="portfolio-list-wrapper"
            style={{ padding: '0 20px', paddingBottom: '80px' }}
        >
            {images?.map((image: any, index: number) => {
                return (
                    <div className="portfolio-list-sub-wrapper" key={index}>
                        {image.length === 1 ? (
                            <div
                                className="portfolio-list-one-detail"
                                style={{ cursor: 'default' }}
                            >
                                <img
                                    src={image[0]}
                                    alt="포트폴리오 이미지"
                                    loading="lazy"
                                    placeholder="blurred"
                                />
                            </div>
                        ) : (
                            <div
                                className="portfolio-list-two-detail"
                                style={{
                                    cursor: 'default'
                                }}
                            >
                                <img
                                    src={image[0]}
                                    alt="포트폴리오 이미지"
                                    loading="lazy"
                                    placeholder="blurred"
                                />
                                <img
                                    src={image[1]}
                                    alt="포트폴리오 이미지"
                                    loading="lazy"
                                    placeholder="blurred"
                                />
                            </div>
                        )}
                    </div>
                );
            })}
        </section>
    );
};

export default Content;
