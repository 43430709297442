import './index.css';

import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { removeBrTag } from '@/utils/common';
import {
    portfolioListContentALL,
    portfolioListContentBranding,
    portfolioListContentPublic,
    portfolioListContentSpace
} from '@/utils/contents';
import { useMediaQuery } from '@mui/material';

const PortfolioList = () => {
    const router = useNavigate();

    const [searchParams] = useSearchParams();

    const [isHover, setIsHover] = useState<number>(0);
    const [filteredContent, setFilteredContent] = useState<any>([]);

    const currentCategory = searchParams.get('category') || 'ALL';

    const mb = useMediaQuery('(max-width: 745px)');

    // 마우스 hover 시 이벤트 //
    const handleMouseEnter = (id: number) => {
        setIsHover(id);
    };

    // 마우스 hover 떠날 시 이벤트
    const handleMouseLeave = () => {
        setIsHover(0);
    };

    // 네비게이션 이벤트
    const handleNavigation = (id: number, item: any) => {
        router(`/portfolio/${id}`, { state: item });
    };

    useEffect(() => {
        // 카테고리에 따른 필터링 로직
        switch (currentCategory) {
            case 'ALL':
                setFilteredContent(portfolioListContentALL);
                break;
            case 'Branding':
                setFilteredContent(portfolioListContentBranding);
                break;
            case 'Space':
                setFilteredContent(portfolioListContentSpace);
                break;
            case 'Public':
                setFilteredContent(portfolioListContentPublic);
                break;
            default:
                setFilteredContent(portfolioListContentALL);
                break;
        }
    }, [currentCategory]);

    return (
        <section className="portfolio-list-wrapper">
            {filteredContent.map((content: any, index: number) => {
                return (
                    <div className="portfolio-list-sub-wrapper" key={index}>
                        {content.length === 1 ? (
                            <div
                                className="portfolio-list-one"
                                onClick={() =>
                                    handleNavigation(content[0].id, content[0])
                                }
                                onMouseEnter={() =>
                                    handleMouseEnter(content[0].id)
                                }
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="overlay"></div>
                                <img
                                    className={
                                        isHover === content[0].id
                                            ? 'active'
                                            : ''
                                    }
                                    loading="lazy"
                                    src={content[0].img}
                                    alt="포트폴리오 이미지"
                                    style={{
                                        minHeight: mb ? '0' : '200px'
                                    }}
                                />

                                <p
                                    className={`category ${
                                        isHover === content[0].id
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    {content[0].category}
                                </p>

                                <div
                                    className={`content-box ${
                                        isHover === content[0].id
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    <h3
                                        dangerouslySetInnerHTML={{
                                            __html: content[0].title
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="portfolio-list-two">
                                {content.map((item: any, idx: number) => {
                                    return (
                                        <div
                                            key={idx}
                                            onClick={() =>
                                                handleNavigation(item.id, item)
                                            }
                                            onMouseEnter={() =>
                                                handleMouseEnter(item.id)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <div
                                                className={`${
                                                    isHover === item.id
                                                        ? 'overlay'
                                                        : ''
                                                } ${mb && 'overlay'}`}
                                            ></div>
                                            <img
                                                className={
                                                    isHover === item.id
                                                        ? 'active'
                                                        : ''
                                                }
                                                src={item.img}
                                                alt="포트폴리오 이미지"
                                                loading="lazy"
                                                style={{
                                                    minHeight: mb
                                                        ? '0'
                                                        : '300px'
                                                }}
                                            />

                                            <p
                                                className={`category ${
                                                    isHover === item.id
                                                        ? 'active'
                                                        : ''
                                                }`}
                                            >
                                                {item.category}
                                            </p>

                                            <div
                                                className={`content-box ${
                                                    isHover === item.id
                                                        ? 'active'
                                                        : ''
                                                }`}
                                            >
                                                <h3
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.title
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                );
            })}
        </section>
    );
};

export default PortfolioList;
