import './index.css';

import { useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';

import PHONE_ICON from '@/assets/image/contact/phone_icon.svg';
import MAIL_CION from '@/assets/image/contact/mail_icon.svg';
import LOCATION_ICON from '@/assets/image/contact/location_icon.svg';
import MARKER_ICON from '@/assets/image/contact/marker_icon.svg';

const ContactLocation = () => {
    const { naver } = window;

    let map: naver.maps.Map;

    const [newMap, setNewMap] = useState<naver.maps.Map | null>(null);

    const mb = useMediaQuery('(max-width: 745px)');

    useEffect(() => {
        const mapOptions = {
            center: new naver.maps.LatLng(37.5518128, 126.9184617),
            zoom: 17
        };

        map = new naver.maps.Map('map', mapOptions);

        const marker = new naver.maps.Marker({
            position: new naver.maps.LatLng(37.5518128, 126.9184617),
            map: map,
            title: 'Unus Korea',
            icon: {
                url: MARKER_ICON,
                size: new naver.maps.Size(50, 50),
                scaledSize: new naver.maps.Size(50, 50),
                origin: new naver.maps.Point(0, 0),
                anchor: new naver.maps.Point(25, 50)
            },
            zIndex: 100
        });

        // 말풍선
        const infoWindow = new naver.maps.InfoWindow({
            content: `
                <div style="
                    padding: 10px 15px;
                    background-color: #EC0033;
                    border-radius: 8px;
                    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
                    color: #ffffff;
                    font-family: 'Arial', sans-serif;
                    font-size: 14px;
                    text-align: center;
                ">
                    UNUS KOREA
                </div>`,
            anchorSize: new naver.maps.Size(0, 0),
            anchorSkew: true,
            anchorColor: '#ffffff',
            borderWidth: 0
        });

        infoWindow.open(map, marker);
    }, [naver, newMap, mb]);

    return (
        <section className="contact-location-wrapper">
            <div className="contact-location-box">
                <div className="contact-location-text">
                    <p>Location</p>
                    <p>
                        <img src={LOCATION_ICON} alt="location" />
                        <span>서울 마포구 양화로 12길 24, 3층</span>
                    </p>
                    <p>
                        <img src={PHONE_ICON} alt="phone" />
                        <span>02-6925-5578</span>
                    </p>
                    <p>
                        <img src={MAIL_CION} alt="mail" />
                        <span>info@unuskorea.com</span>
                    </p>
                </div>
                <div className="contact-location-map">
                    <div
                        id="map"
                        style={{
                            width: '100%',
                            height: mb ? '500px' : '700px',
                            borderRadius: '16px'
                        }}
                    />
                </div>
            </div>
        </section>
    );
};

export default ContactLocation;
