// About 페이지의 Title 상수
export const ABOUT_TITLE = [
    'Passionate',
    'Innovative',
    'Creative',
    'Dynamic',
    'Dedicated'
];

interface IAboutHistory {
    year: string;
    content: {
        text: string;
        icon: boolean;
        tooltip?: string;
    }[];
}

// About 페이지의 History 상수
export const ABOUT_HISTORY: IAboutHistory[] = [
    {
        year: '2023',
        content: [
            {
                text: '중기업 선정',
                icon: false
            },
            {
                text: '대한민국 광고대상 프로모션 부문 은상',
                icon: false
            },
            {
                text: '한국 이벤트 대상 금상',
                icon: false
            },
            {
                text: '대홍기획 베스트 파트너 선정',
                icon: false
            },
            {
                text: '한미동맹 70주년 기념행사 (VIP 국빈 방미)',
                icon: false
            },
            {
                text: '2030부산세계박람회 유치활동 BIE 실사단 방문',
                icon: true
            },
            {
                text: '2030부산세계박람회 국내외 유치 홍보',
                icon: true
            },
            {
                text: '2030부산세계박람회 대한항공 특별기 공개 행사',
                icon: true
            }
        ]
    },
    {
        year: '2022',
        content: [
            { text: '대홍기획 베스트 파트너 선정', icon: false },
            { text: '2022 해외한국문화축제', icon: false },
            { text: '2030부산세계박람회 해외 프로모션', icon: false },
            { text: '한화그룹 LIFEPLUS TRIBES', icon: false }
        ]
    },
    {
        year: '2021',
        content: [
            { text: '여수 미디어아트뮤지엄 ‘녹테마레’', icon: false },
            { text: '2030부산세계박람회 마스터플랜 수립', icon: true },
            { text: '한화생명 LIFE ADVISOR 캠페인', icon: false },
            { text: '김포평화축제', icon: false },
            { text: '포항 환호공원 스페이스워크 제막식', icon: false }
        ]
    },
    {
        year: '2020',
        content: [
            { text: '미래 성과공유기업 인증', icon: false },
            {
                text: 'SKT 플래그십스토어 ‘T Factory’ 오픈 프로모션',
                icon: false
            },
            { text: 'SKT 갤럭시 노트20 드라이브인 개통행사', icon: false }
        ]
    },
    {
        year: '2019',
        content: [
            { text: '기업부설연구소 설립', icon: false },
            {
                text: '2030부산세계박람회 마스터플랜 및 Bid Dossier 수립',
                icon: true
            },
            { text: '고용노동부 「청년내일채움공제」 가입', icon: false }
        ]
    },
    {
        year: '2017',
        content: [{ text: '유앤어스코리아설립', icon: false }]
    }
];
