import './index.css';

import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import secondImg01 from '@/assets/image/main/second_01.png';
import secondImg02 from '@/assets/image/main/second_04.png';
import secondImg03 from '@/assets/image/main/second_03.png';
import secondImg04 from '@/assets/image/main/second_02.png';

import secondImgMb01 from '@/assets/image/main/second_mb_01.png';
import secondImgMb02 from '@/assets/image/main/second_mb_02.png';
import secondImgMb03 from '@/assets/image/main/second_mb_03.png';
import secondImgMb04 from '@/assets/image/main/second_mb_04.png';

gsap.registerPlugin(ScrollTrigger);

interface SecondAreaProps {
    animateRef: React.RefObject<HTMLDivElement>;
}

const SecondArea = ({ animateRef }: SecondAreaProps) => {
    const mb = useMediaQuery('(max-width: 745px)');

    useEffect(() => {
        const section = animateRef.current;

        if (section) {
            const endTrigger = `+=${section.offsetHeight}`;

            gsap.fromTo(
                '.second-wrapper > .second-text-box > p:nth-child(odd)',
                { y: 0, x: -1500, opacity: 1 },
                {
                    y: 0,
                    x: 0,
                    opacity: 1,
                    duration: 2,
                    scrollTrigger: {
                        trigger: section,
                        start: mb ? 'top bottom' : 'top bottom',
                        end: () => `+=${section.offsetHeight}`,
                        scrub: 4
                    },
                    ease: 'power2.out'
                }
            );

            gsap.fromTo(
                '.second-wrapper > .second-text-box > p:nth-child(even)',
                { y: 0, x: 1500, opacity: 1 },
                {
                    y: 0,
                    x: 0,
                    opacity: 1,
                    duration: 2,
                    scrollTrigger: {
                        trigger: section,
                        start: mb ? 'top bottom' : 'top bottom',
                        end: () => `+=${section.offsetHeight}`,
                        scrub: 4
                    },
                    ease: 'power2.out'
                }
            );

            // 이미지에 대한 애니메이션 설정
            gsap.from('.second-wrapper > .second-img > img', {
                y: 200,
                opacity: 0,
                duration: 2,
                delay: 0.5,
                scrollTrigger: {
                    trigger: section,
                    start: 'top end+=100',
                    end: '+=100%',
                    toggleActions: 'play none none none'
                },
                ease: 'power3.out'
            });

            gsap.fromTo(
                '.second-wrapper > .second-text-box > p:nth-child(odd)',
                { y: 0, x: 0, opacity: 1 },
                {
                    y: 0,
                    x: mb ? 500 : 1000,
                    opacity: 0,
                    duration: 2,
                    scrollTrigger: {
                        trigger: section,
                        start: mb ? 'top+=200' : 'top+=100',
                        end: endTrigger,
                        scrub: 1
                    },
                    ease: 'power2.out'
                }
            );

            gsap.fromTo(
                '.second-wrapper > .second-text-box > p:nth-child(even)',
                { y: 0, x: 0, opacity: 1 },
                {
                    y: 0,
                    x: mb ? -500 : -1000,
                    opacity: 0,
                    duration: 2,
                    scrollTrigger: {
                        trigger: section,
                        start: mb ? 'top+=200' : 'top+=100',
                        end: endTrigger,
                        scrub: 1
                    },
                    ease: 'power2.out'
                }
            );
        }
    }, [mb]);

    return (
        <section className="second-wrapper" ref={animateRef}>
            <div className="second-text-box">
                <p>we provide</p>
                <p>the best solutions</p>
                <p>to make the world</p>
                <p>valuable</p>
            </div>

            <picture className="second-img">
                <source
                    srcSet={mb ? secondImgMb01 : secondImg01}
                    type="image/png"
                />
                <img src={mb ? secondImgMb01 : secondImg01} alt="secondImg01" />
            </picture>

            <picture className="second-img">
                <source
                    srcSet={mb ? secondImgMb04 : secondImg02}
                    type="image/png"
                />
                <img src={mb ? secondImgMb04 : secondImg02} alt="secondImg02" />
            </picture>

            <picture className="second-img">
                <source
                    srcSet={mb ? secondImgMb03 : secondImg03}
                    type="image/png"
                />
                <img src={mb ? secondImgMb03 : secondImg03} alt="secondImg03" />
            </picture>

            <picture className="second-img">
                <source
                    srcSet={mb ? secondImgMb02 : secondImg04}
                    type="image/png"
                />
                <img src={mb ? secondImgMb02 : secondImg04} alt="secondImg04" />
            </picture>
        </section>
    );
};

export default SecondArea;
