import './index.css';
import { useEffect } from 'react';

import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';

import { ABOUT_TITLE } from '@/utils/const';

const AboutTitle = () => {
    useEffect(() => {
        gsap.registerPlugin(TextPlugin);

        const aboutTitleBox = document.querySelector('.about-title');
        const aboutTitle = document.querySelector('.about-title h1');

        const tl = gsap.timeline({
            repeat: -1,
            repeatDelay: 1
        });

        tl.fromTo(
            aboutTitleBox,
            { opacity: 1 },
            {
                duration: 0.5,
                ease: 'sine.inOut',
                opacity: 1
            }
        );

        ABOUT_TITLE.forEach(title => {
            const chars = title.split('');
            chars.forEach((char, index) => {
                tl.to(aboutTitle, {
                    duration: 0.1,
                    text: {
                        value: title.substring(0, index + 1),
                        delimiter: ''
                    },
                    ease: 'sine.inOut'
                });
            });

            tl.to(aboutTitle, {
                duration: 0.5,
                ease: 'sine.inOut',
                delay: 0.5
            });
        });

        tl.play();

        return () => {
            tl.kill();
        };
    }, []);

    return (
        <section className="about-title-wrapper">
            <div className="about-title-sub-wrapper">
                <div className="about-title">
                    <h1></h1>
                </div>

                <div className="about-sub-title">
                    <p>as UNUS</p>
                </div>
            </div>
        </section>
    );
};

export default AboutTitle;
