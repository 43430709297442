import './index.css';

interface InformationProps {
    content: any;
}

const Information = ({ content }: InformationProps) => {
    return (
        <section className="information-wrapper">
            <div className="information-title">
                <p dangerouslySetInnerHTML={{ __html: content.title }} />
                <p>{`${content.desc}`}</p>
            </div>

            <div className="information-desc">
                <p>information</p>
                <div className="divider" />
                <div className="information-content">
                    <p>
                        <span>Client</span>
                        <span>{content.client}</span>
                    </p>
                    <p>
                        <span>Date</span>
                        <span>{content.date}</span>
                    </p>
                    <p>
                        <span>Place</span>
                        <span>{content.place}</span>
                    </p>
                    <p>
                        <span>Category</span>
                        <span>{content.category}</span>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Information;
