import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import PageContainer from '@/components/Container/PageContainer';
import { Content, Information, Other } from '../components/portfolioDetail';
import { portfolioListContentALL } from '@/utils/contents';

const PortfolioDetail = () => {
    const { state } = useLocation();

    const [item, setItem] = useState<any>({});

    useEffect(() => {
        portfolioListContentALL.forEach((element: any) => {
            element.forEach((item: any) => {
                if (item.id === state.id) {
                    setItem(item);
                }
            });
        });
    }, [state, item]);

    return (
        <PageContainer>
            <Information content={item} />
            <Content content={item} />
            <Other content={item} />
        </PageContainer>
    );
};

export default PortfolioDetail;
