import './index.css';

import { useEffect } from 'react';

import gsap from 'gsap';

const CarrerTitle = () => {
    useEffect(() => {
        gsap.from('.carrer-title-box', {
            y: 50,
            opacity: 0,
            duration: 2,
            ease: 'power2.out'
        });
    }, []);

    return (
        <section className="carrer-title-wrapper">
            <div className="carrer-title-box">
                <h2>
                    Bold-<span className="italic-text">phy</span>
                </h2>

                <p>
                    Boldphy는 Bold(대담한)와 –phy의 결합어로 전문적인 지식과
                    대담한 행동이 결합된 의미로, 새로운 과제를 수행할 때
                    과감하고 창의적인 <br /> 해결책을 찾아내는 우리의 모습을
                    나타냅니다. 이 단어는 프로젝트의 본질을 이해하고 그것을
                    우리의 솔루션에 적용하는 것을 강조하며, <br /> 동시에
                    대담하고 창의적인 사고를 통해 새로운 가능성을 탐험하는 것을
                    상징합니다.
                </p>
            </div>
        </section>
    );
};

export default CarrerTitle;
