import { useRef } from 'react';

import PageContainer from '@/components/Container/PageContainer';
import { MainScreen, SecondArea, Portfolio } from '../components/main';

const Main = () => {
    const secondAreaRef = useRef<HTMLDivElement>(null);

    return (
        <PageContainer>
            <MainScreen secondAreaRef={secondAreaRef} />
            <SecondArea animateRef={secondAreaRef} />
            <Portfolio />
        </PageContainer>
    );
};

export default Main;
