import './index.css';

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import gsap from 'gsap';

const BUTTONS = ['ALL', 'Branding', 'Space', 'Public'];

const PortfolioTitle = () => {
    const [catergory, setCategory] = useSearchParams();

    // 버튼 클릭 시 해당 카테고리로 이동
    const handleCategoryBtn = (category: string) => {
        setCategory({ category });
    };

    // 카테고리 값이 없을 경우 ALL로 초기화
    useEffect(() => {
        if (!catergory.get('category')) {
            setCategory({ category: 'ALL' });
        }
    }, [catergory]);

    useEffect(() => {
        gsap.from('.portfolio-title-wrapper', {
            y: 50,
            opacity: 0,
            duration: 2,
            ease: 'power2.out'
        });
    }, []);

    return (
        <section className="portfolio-title-wrapper">
            <div className="portfolio-title-sub-wrapper">
                <div className="portfolio-title">
                    <h1>
                        Our <span className="italic-text">Portfolio</span>
                    </h1>

                    <p>We create an inspiring experience</p>
                </div>

                <div className="portfolio-category-btn">
                    {BUTTONS.map((button, index) => (
                        <button
                            key={index}
                            className={`btn ${
                                catergory.get('category') === button
                                    ? 'active'
                                    : ''
                            }`}
                            onClick={() => handleCategoryBtn(button)}
                        >
                            {button}
                        </button>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PortfolioTitle;
