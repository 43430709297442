import './index.css';

import ActivityLongImg from '../../../assets/image/about/about_activity_img.png';

const Activity = () => {
    return (
        <section className="activity-wrapper">
            <div className="activity-sub-wrapper">
                <div className="activity-title-box">
                    <p className="activity-title">
                        Our <span className="italic-text">activity</span>
                    </p>
                    <p className="activity-sub-title">
                        UNUS KOREA는 2030부산세계박람회 유치홍보과업을 <br />{' '}
                        시작부터 완료까지 성공적으로 수행하였습니다.
                    </p>
                </div>

                <div className="activity-slide-box">
                    <div className="activity-slide">
                        <img src={ActivityLongImg} alt="activity_img" />
                        <img src={ActivityLongImg} alt="activity_img" />
                        <img src={ActivityLongImg} alt="activity_img" />
                        <img src={ActivityLongImg} alt="activity_img" />
                        <img src={ActivityLongImg} alt="activity_img" />
                        <img src={ActivityLongImg} alt="activity_img" />
                        <img src={ActivityLongImg} alt="activity_img" />
                        <img src={ActivityLongImg} alt="activity_img" />
                        <img src={ActivityLongImg} alt="activity_img" />
                        <img src={ActivityLongImg} alt="activity_img" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Activity;
