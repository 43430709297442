// components
import PageContainer from '@/components/Container/PageContainer';
import {
    CarrerTitle,
    DesiredQuality,
    Welfare,
    Recruit
} from '../components/carrer';

// 커리어 페이지
const Career = () => {
    return (
        <PageContainer>
            <CarrerTitle />
            <DesiredQuality />
            <Welfare />
            <Recruit />
        </PageContainer>
    );
};

export default Career;
