import './index.css';

import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// assets
import DESIRED_TITLE from '@/assets/image/carrer/desired_title.png';
import DESIRED_TITLE_SMALL from '@/assets/image/carrer/desired_title_sm.png';
import DESIRED_IMG_01 from '@/assets/image/carrer/desire_01.png';
import DESIRED_IMG_02 from '@/assets/image/carrer/desire_02.png';
import DESIRED_IMG_03 from '@/assets/image/carrer/desire_03.png';

const DesiredQuality = () => {
    const tb = useMediaQuery('(max-width: 1280px)');
    const mb = useMediaQuery('(max-width: 745px)');

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.from('.carrer-desired-box', {
            y: 50,
            opacity: 0,
            duration: 2,
            ease: 'power2.out',
            scrollTrigger: {
                trigger: '.carrer-desired-box',
                start: 'top 90%',
                toggleActions: 'play none none none'
            }
        });

        const circles = gsap.utils.toArray('.circle');

        circles.forEach((circle, index) => {
            const delay = index * 0.2;

            gsap.fromTo(
                circle as any,
                {
                    y: 50,
                    autoAlpha: 0
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 2,
                    delay: delay,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: circle as any,
                        start: 'top 90%',
                        toggleActions: 'play none none none'
                    }
                }
            );
        });
    }, []);

    return (
        <section className="carrer-desired-wrapper">
            <div className="carrer-desired-box">
                <h2>
                    <img
                        src={mb ? DESIRED_TITLE_SMALL : DESIRED_TITLE}
                        alt="desired_title"
                    />
                </h2>

                <div className="carrer-desired-circle">
                    <div className="circle">
                        {!mb && (
                            <>
                                <div className="content front">
                                    <p>
                                        <span>(1)</span>
                                        <span>
                                            Bold <br /> Thinker
                                        </span>
                                    </p>
                                </div>
                                <div className="content back">
                                    <p>
                                        <span>대담한 사고법</span>
                                        <span>
                                            우리는 새로운 도전에 대한 두려움
                                            없이, 대담한 사고와 창의적인
                                            아이디어로 미래를 개척합니다. Bold한
                                            사고로 상상력의 한계를 뛰어넘습니다.
                                        </span>
                                    </p>
                                </div>
                            </>
                        )}
                        {mb && (
                            <div className="cotent mobile">
                                <p>
                                    <span>대담한 사고법</span>
                                    <span>Bold Thinker</span>
                                </p>
                                <p>
                                    우리는 새로운 도전에 대한 두려움 없이,
                                    대담한 사고와 창의적인 아이디어로 미래를
                                    개척합니다. Bold한 사고로 <br /> 상상력의
                                    한계를 뛰어넘습니다.
                                </p>
                            </div>
                        )}
                        <div className="circle-img">
                            <img
                                src={tb ? DESIRED_IMG_02 : DESIRED_IMG_01}
                                alt="desired_img_01"
                            />
                        </div>
                    </div>
                    <div className="circle">
                        {!mb && (
                            <>
                                <div className="content front">
                                    <p>
                                        <span>(2)</span>
                                        <span>
                                            Fearless
                                            <br /> Trailblazers
                                        </span>
                                    </p>
                                </div>
                                <div className="content back">
                                    <p>
                                        <span>두려움 없는 개척자</span>
                                        <span>
                                            우리는 어떤 도전이라도 두려움 없이
                                            받아들이고, 새로운 길을 개척합니다.
                                            혁신적인 솔루션으로 <br /> 업계의
                                            선두에 서고자 합니다.
                                        </span>
                                    </p>
                                </div>
                            </>
                        )}

                        {mb && (
                            <div className="cotent mobile">
                                <p>
                                    <span>두려움 없는 개척자</span>
                                    <span>Fearless Trailblazers</span>
                                </p>
                                <p>
                                    우리는 어떤 도전이라도 두려움 없이
                                    받아들이고, 새로운 길을 개척합니다. 혁신적인
                                    솔루션으로 업계의 선두에 <br /> 서고자
                                    합니다.
                                </p>
                            </div>
                        )}

                        <div className="circle-img">
                            <img
                                src={tb ? DESIRED_IMG_01 : DESIRED_IMG_02}
                                alt="desired_img_02"
                            />
                        </div>
                    </div>
                    <div className="circle">
                        {!mb && (
                            <>
                                <div className="content front">
                                    <p>
                                        <span>(3)</span>
                                        <span>
                                            Authentic
                                            <br /> Dreamers
                                        </span>
                                    </p>
                                </div>
                                <div className="content back">
                                    <p>
                                        <span>진정한 꿈꾸는 자</span>
                                        <span>
                                            우리는 진정성 있는 꿈을 향해
                                            나아가는 사람들입니다. 꿈을
                                            실현하고, 세상에 긍정적인 영향을{' '}
                                            <br />
                                            끼치는 것이 우리의 목표입니다.
                                        </span>
                                    </p>
                                </div>
                            </>
                        )}

                        {mb && (
                            <div className="cotent mobile">
                                <p>
                                    <span>진정한 꿈꾸는 자</span>
                                    <span>Authentic Dreamers </span>
                                </p>
                                <p>
                                    우리는 진정성 있는 꿈을 향해 나아가는
                                    사람들입니다. 꿈을 실현하고, 세상에 긍정적인
                                    영향을 끼치는 것이 우리의 목표입니다.
                                </p>
                            </div>
                        )}

                        <div className="circle-img">
                            <img src={DESIRED_IMG_03} alt="desired_img_03" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DesiredQuality;
