import './index.css';

import { Fragment } from 'react';

import { ABOUT_HISTORY } from '@/utils/const';

import HISTROY_TITLE_IMG from '@/assets/image/about/history_title.png';
import HISTROY_TITLE_SMALL_IMG from '@/assets/image/about/history_title_sm.png';
import HISTROY_ICON from '@/assets/image/about/history_icon.svg';
import { useMediaQuery } from '@mui/material';

const History = () => {
    const mb = useMediaQuery('(max-width: 745px)');

    return (
        <section className="history-wrapper">
            <div className="history-sub-wrapper">
                <div className="history-title-overlay">
                    <img
                        src={mb ? HISTROY_TITLE_SMALL_IMG : HISTROY_TITLE_IMG}
                        alt="history title imgage"
                    />
                </div>

                <div className="history-main-box">
                    {ABOUT_HISTORY.map((history, index) => (
                        <div className="history-sub-box" key={index}>
                            <div className="history-main-title">
                                <h2>{history.year}</h2>
                            </div>
                            <div className="history-main-content">
                                {history.content.map((content, index) => (
                                    <Fragment key={index}>
                                        {content.icon ? (
                                            <p
                                                style={{
                                                    paddingTop: content.tooltip
                                                        ? '52px'
                                                        : '0'
                                                }}
                                            >
                                                {content.tooltip && (
                                                    <span className="tooltip">
                                                        {content.tooltip}
                                                    </span>
                                                )}
                                                <img
                                                    src={HISTROY_ICON}
                                                    alt="history icon"
                                                />
                                                <span>{content.text}</span>
                                            </p>
                                        ) : (
                                            <p>{content.text}</p>
                                        )}
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default History;
