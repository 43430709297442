import './index.css';

import { ReactComponent as TopButtonIcon } from '@/assets/image/main/top_button_icon.svg';

const TopButton = () => {
    const handleTopButton = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div
            className="top-button"
            onClick={handleTopButton}
            role="button"
            tabIndex={0}
        >
            <TopButtonIcon />
        </div>
    );
};

export default TopButton;
