import './index.css';
import { Outlet } from 'react-router-dom';

import Header from '@/components/Header/header';
import Footer from '@/components/Footer/footer';
import TopButton from '@/components/TopButton';

const AdminLayout = () => {
    return (
        <div className="AppRoot">
            <Header />
            <Outlet />
            <Footer />
            <TopButton />
        </div>
    );
};

export default AdminLayout;
