import PageContainer from '@/components/Container/PageContainer';
import { PortfolioList, PortfolioTitle } from '../components/portfolio';

const Portfolio = () => {
    return (
        <PageContainer>
            <PortfolioTitle />
            <PortfolioList />
        </PageContainer>
    );
};

export default Portfolio;
