import PageContainer from '@/components/Container/PageContainer';
import {
    ContactForm,
    ContactLocation,
    ContactTitle
} from '../components/contact';

const Contact = () => {
    return (
        <PageContainer>
            <ContactTitle />
            <ContactForm />
            <ContactLocation />
        </PageContainer>
    );
};

export default Contact;
