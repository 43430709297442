import './index.css';

import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { portfolioContent } from '@/utils/contents';

gsap.registerPlugin(ScrollTrigger);

const Portfolio = () => {
    const sectionRef = useRef<HTMLDivElement>(null);

    const router = useNavigate();

    useEffect(() => {
        if (sectionRef.current) {
            gsap.from('.portfolio-text h2', {
                scrollTrigger: {
                    trigger: sectionRef.current,
                    start: 'top 80%'
                },
                duration: 2,
                y: 100,
                opacity: 0,
                delay: 0.3
            });

            gsap.from('.portfolio-img-box', {
                scrollTrigger: {
                    trigger: sectionRef.current,
                    start: 'top 80%'
                },
                duration: 2,
                y: 100,
                opacity: 0,
                delay: 0.3,
                stagger: 0.5
            });
        }
    }, []);

    return (
        <section
            className="portfolio-wrapper"
            ref={sectionRef}
            data-scroll-section
        >
            <div className="portfolio-text">
                <h2>
                    Our <span>Portfolio</span>
                </h2>
            </div>

            <div className="portfolio-img">
                {portfolioContent.map((item, idx) => {
                    return (
                        <div
                            key={idx}
                            className="portfolio-img-box"
                            onClick={() =>
                                router(`/portfolio/${item.id}`, { state: item })
                            }
                        >
                            <div className="overlay" />
                            <img src={item.img} alt="portfolio" />

                            <div className="portfolio-img-overlay">
                                <div className="portfolio-img-text">
                                    <p>{item.title}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default Portfolio;
