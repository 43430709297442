import AppLayout from '@/layout/appLayout';

import Main from '@/pages/section/main';
import About from '@/pages/section/about';
import Portfolio from '@/pages/section/portfolio';
import PortfolioDetail from '@/pages/section/portfolioDetail';
import Career from '@/pages/section/career';
import Contact from '@/pages/section/contact';

const AppRoutes = {
    path: '/',
    element: <AppLayout />,
    children: [
        { path: '/', element: <Main /> },
        { path: '/about', element: <About /> },
        { path: '/portfolio', element: <Portfolio /> },
        { path: '/portfolio/:id', element: <PortfolioDetail /> },
        { path: '/career', element: <Career /> },
        { path: '/contact', element: <Contact /> }
    ]
};

export default AppRoutes;
