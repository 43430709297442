import './PageContainer.css';
import { ReactNode } from 'react';

interface PageContainerProps {
    children: ReactNode;
    path?: string
}

const PageContainer = ({ children, path }: PageContainerProps) => {

    const className = `${path === 'auth' ? 'container-long container-center' : 'container-normal'}`;

    return (
        <div className={className}>
            {children}
        </div>
    );

};

export default PageContainer;
