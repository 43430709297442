import './index.css';

import { useNavigate } from 'react-router-dom';

interface OtherProps {
    content: any;
}

const Other = ({ content }: OtherProps) => {
    const { others } = content;
    const router = useNavigate();

    return (
        <section className="other-wrapper">
            <p className="other-title">Other Portfolio</p>
            <div className="other-images">
                {others?.map((item: any, index: number) => (
                    <div
                        key={index}
                        className="other-image"
                        onClick={() =>
                            router(`/portfolio/${item.id}`, { state: item })
                        }
                    >
                        <img src={item.img} alt={item.title} />

                        <div className="other-image-overlay">
                            <div>
                                <p className="other-image-title">
                                    {item.title.replace(/<br\s*\/?>/gi, ' ')}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Other;
