import './index.css';

import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import RECRUIT_TITLE from '@/assets/image/carrer/recruit_title.png';
import RECRUIT_TITLE_SMALL from '@/assets/image/carrer/recruit_title_sm.png';

const Recruit = () => {
    const mb = useMediaQuery('(max-width: 745px)');

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.from('.recruit-box', {
            y: 50,
            opacity: 0,
            duration: 2,
            ease: 'power2.out',
            scrollTrigger: {
                trigger: '.recruit-box',
                start: 'top 90%',
                toggleActions: 'play none none none'
            }
        });

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.recruit-card:first-child',
                start: 'top 90%',
                toggleActions: 'play none none none'
            }
        });

        gsap.utils.toArray('.recruit-card').forEach((card, index) => {
            tl.fromTo(
                card as any,
                {
                    y: 50,
                    autoAlpha: 0
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 1,
                    delay: index * 0.2,
                    ease: 'power2.out'
                },
                0
            );
        });
    }, []);

    return (
        <section className="recruit-wrapper">
            <div className="recruit-box">
                <h2 className="recruit-title">
                    <img
                        src={mb ? RECRUIT_TITLE_SMALL : RECRUIT_TITLE}
                        alt="채용 타이틀"
                    />
                </h2>

                <div className="recruit-content">
                    <div
                        className="recruit-card"
                        onClick={() => alert('마감된 채용공고입니다.')}
                    >
                        <div className="card-status end">마감</div>
                        <div className="card-text-box">
                            <p className="main-text">
                                유앤어스코리아 23년도 BTL 마케팅 경력/신입사원
                                모집
                            </p>
                            <p className="sub-text">(~2023.04.01)</p>
                        </div>
                    </div>

                    <div
                        className="recruit-card"
                        onClick={() => alert('마감된 채용공고입니다.')}
                    >
                        <div className="card-status end">마감</div>
                        <div className="card-text-box">
                            <p className="main-text">
                                (주)유앤어스코리아 프로모션본부 신입/경력 채용
                            </p>
                            <p className="sub-text">(~2022.05.18)</p>
                        </div>
                    </div>

                    <div
                        className="recruit-card"
                        onClick={() => alert('마감된 채용공고입니다.')}
                    >
                        <div className="card-status end">마감</div>
                        <div className="card-text-box">
                            <p className="main-text">
                                (주)유앤어스코리아 프로모션본부 신입사원을
                                모집합니다.
                            </p>
                            <p className="sub-text">(~2022.04.18)</p>
                        </div>
                    </div>

                    <div
                        className="recruit-card"
                        onClick={() => alert('마감된 채용공고입니다.')}
                    >
                        <div className="card-status end">마감</div>
                        <div className="card-text-box">
                            <p className="main-text">
                                (주)유앤어스코리아 프로모션본부 경력직원을
                                모집합니다.
                            </p>
                            <p className="sub-text">(~2021.12.16)</p>
                        </div>
                    </div>

                    <div
                        className="recruit-card"
                        onClick={() => alert('마감된 채용공고입니다.')}
                    >
                        <div className="card-status end">마감</div>
                        <div className="card-text-box">
                            <p className="main-text">
                                (주)유앤어스코리아 프로모션본부 신입사원을
                                모집합니다.
                            </p>
                            <p className="sub-text">(~2021.12.16)</p>
                        </div>
                    </div>

                    <div
                        className="recruit-card"
                        onClick={() => alert('마감된 채용공고입니다.')}
                    >
                        <div className="card-status end">마감</div>
                        <div className="card-text-box">
                            <p className="main-text">
                                UNUS KOREA BTL프로모션/브랜드마케팅/전시 기획자
                                경력직 채용
                            </p>
                            <p className="sub-text">(~2021.08.14)</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Recruit;
