/* eslint-disable indent */
import './index.css';

import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import Logo from '@/assets/unus_logo.svg';

import { ReactComponent as MenuIcon } from '@/assets/image/header/menu.svg';
import { ReactComponent as CloseIcon } from '@/assets/image/header/close_icon.svg';

const Header = () => {
    const mb = useMediaQuery('(max-width: 745px)');

    const { pathname } = useLocation();

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const handleMenuOpen = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header-wrapper">
            <nav className="header-sub-wrapper">
                <div className="header-logo">
                    <Link to="/">
                        <img src={Logo} alt="logo" />
                    </Link>
                </div>
                {!mb ? (
                    <ul className="header-menu">
                        <li className="header-menu-item">
                            <Link
                                to="/about"
                                className={
                                    pathname === '/about' ? 'active' : ''
                                }
                            >
                                About
                            </Link>
                        </li>
                        <li className="header-menu-item">
                            <Link
                                to="/portfolio"
                                className={
                                    pathname === '/portfolio' ? 'active' : ''
                                }
                            >
                                Portfolio
                            </Link>
                        </li>
                        <li className="header-menu-item">
                            <Link
                                to="/career"
                                className={
                                    pathname === '/career' ? 'active' : ''
                                }
                            >
                                Career
                            </Link>
                        </li>
                        <li className="header-menu-item">
                            <Link
                                to="/contact"
                                className={
                                    pathname === '/contact' ? 'active' : ''
                                }
                            >
                                Contact
                            </Link>
                        </li>
                    </ul>
                ) : (
                    <>
                        <MenuIcon
                            className={`header-menu-icon${
                                isMenuOpen ? ' hide' : ''
                            }`}
                            onClick={handleMenuOpen}
                        />
                        <CloseIcon
                            className={`header-menu-icon${
                                isMenuOpen ? '' : ' hide'
                            }`}
                            onClick={handleMenuOpen}
                        />
                    </>
                )}

                {mb && (
                    <ul
                        className={`header-dropdown-menu ${
                            isMenuOpen ? 'show' : 'hide'
                        }`}
                    >
                        <div className="header-dropdown-menu-inner">
                            <li className="header-dropdown-menu-item">
                                <Link to="/about" onClick={handleMenuOpen}>
                                    About
                                </Link>
                            </li>
                            <li className="header-dropdown-menu-item">
                                <Link to="/portfolio" onClick={handleMenuOpen}>
                                    Portfolio
                                </Link>
                            </li>
                            <li className="header-dropdown-menu-item">
                                <Link to="/career" onClick={handleMenuOpen}>
                                    Career
                                </Link>
                            </li>
                            <li className="header-dropdown-menu-item">
                                <Link to="/contact" onClick={handleMenuOpen}>
                                    Contact
                                </Link>
                            </li>
                        </div>
                    </ul>
                )}
            </nav>
        </header>
    );
};

export default Header;
