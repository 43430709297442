import PageContainer from '@/components/Container/PageContainer';
import {
    AboutTitle,
    Business,
    FadeCard,
    History,
    Activity,
    Clients
} from '../components/about';

const About = () => {
    return (
        <PageContainer>
            <AboutTitle />
            <FadeCard />
            <Business />
            <History />
            <Activity />
            <Clients />
        </PageContainer>
    );
};

export default About;
