import './index.css';

import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import CONTACT_TITLE from '@/assets/image/contact/contact_title.png';
import CONTACT_TITLE_SMALL from '@/assets/image/contact/contact_title_sm.png';
import PDF_FILE from '@/assets/file/company_introduce.pdf';
import { ReactComponent as DownIcon } from '@/assets/image/contact/down_icon.svg';

const ContactTitle = () => {
    const mb = useMediaQuery('(max-width: 750px)');

    useEffect(() => {
        ScrollTrigger.refresh();

        gsap.from('.contact-title-box', {
            y: 50,
            opacity: 0,
            duration: 2,
            ease: 'power2.out'
        });
    }, []);

    return (
        <section className="contact-title-wrapper">
            <div className="contact-title-box">
                <div className="contact-title">
                    <h2>
                        <img
                            src={mb ? CONTACT_TITLE_SMALL : CONTACT_TITLE}
                            alt="contact 타이틀"
                        />
                    </h2>

                    <p>Do you have any questions? Contact us</p>
                </div>

                <button
                    className="contact-button"
                    onClick={() => window.open(PDF_FILE, '_blank')}
                    type="button"
                >
                    <span>UNUS Company Brief</span>
                    <DownIcon />
                </button>
            </div>
        </section>
    );
};

export default ContactTitle;
