import './index.css';

import { clientsContent } from '@/utils/contents';

import CLIENTS_TITLE_IMG from '@/assets/image/about/clients_title.png';
import CLIENTS_TITLE_SMALL_IMG from '@/assets/image/about/clients_title_sm.png';
import { useMediaQuery } from '@mui/material';

const Clients = () => {
    const mb = useMediaQuery('(max-width: 745px)');

    return (
        <section className="clients-wrapper">
            <div className="clients-sub-wrapper">
                <div className="clients-title-overlay">
                    <img
                        src={mb ? CLIENTS_TITLE_SMALL_IMG : CLIENTS_TITLE_IMG}
                        alt="clients title imgage"
                    />
                </div>

                <div className="clients-main-box">
                    <div className="clients-logo-box">
                        {clientsContent.map((content, index) => {
                            return (
                                <div className="clients-logo" key={index}>
                                    <img src={content} alt="clients logo" />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Clients;
