import './index.css';

import { useEffect, useRef } from 'react';
import { useMediaQuery } from '@mui/material';

import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import MainVideo from '@/assets/image/main/main_video.gif';

import { ReactComponent as ArrowDownIcon } from '@/assets/image/header/arrow_down.svg';

interface MainScreenProps {
    secondAreaRef: React.RefObject<HTMLDivElement>;
}

gsap.registerPlugin(ScrollToPlugin);

const MainScreen = ({ secondAreaRef }: MainScreenProps) => {
    const mb = useMediaQuery('(max-width: 745px)');
    const hasScrolled = useRef(false);

    useEffect(() => {
        const video = document.querySelector('.main-screen-img');
        const arrow = document.querySelector('.main-screen-arrow');

        if (video) {
            gsap.from(video, {
                duration: 2,
                y: 100,
                opacity: 0,
                ease: 'power2.out'
            });
        }

        if (arrow) {
            gsap.to(arrow, {
                duration: 0.5,
                y: 20,
                repeat: -1,
                yoyo: true
            });
        }

        const handleScroll = () => {
            if (mb && secondAreaRef.current && !hasScrolled.current) {
                if (window.scrollY > 50) {
                    hasScrolled.current = true;

                    gsap.to(window, {
                        duration: 1,
                        scrollTo: {
                            y: secondAreaRef.current.offsetTop,
                            autoKill: false
                        },
                        ease: 'power2.out',
                        onComplete: () => {}
                    });
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [secondAreaRef]);

    return (
        <section className="main-screen-wrapper">
            <picture className="main-screen-overlay">
                <source srcSet={MainVideo} type="image/gif" />
                <img
                    className="main-screen-img"
                    src={MainVideo}
                    alt="main-screen"
                />
            </picture>

            {mb && (
                <div className="main-screen-arrow">
                    <p>Scroll</p>
                    <ArrowDownIcon />
                </div>
            )}
        </section>
    );
};

export default MainScreen;
